import user from './user';
import temp from './temp';
import global from './global';
import { combineReducers } from 'redux';

const appReducer = combineReducers({
    user, global, temp
})

export default (state, action) => {
    if (action.type === 'PERFORM_LOGOUT') {
        state = undefined
    }
    return appReducer(state, action)
}
