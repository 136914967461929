import React from "react";

// Function to set an item in localStorage with an expiration time
export const setWithExpiry = (key, value, ttl) => {
    const now = new Date();
    const expiry = now.getTime() + ttl; // Calculate expiry time
    localStorage.setItem(key, value);
    localStorage.setItem(`${key}_expiry`, expiry.toString());
}

// Function to get an item from localStorage and remove it if it's expired
export const getWithExpiry = (key) => {
    const now = new Date().getTime();
    const expiry = localStorage.getItem(`${key}_expiry`);
    if (!expiry || now > parseInt(expiry)) {
        // Item has expired or does not exist
        localStorage.removeItem(key);
        localStorage.removeItem(`${key}_expiry`);
        return null;
    }
    return localStorage.getItem(key);
}

export const truncateText = (text, truncateSize) => {
    if (text && text.length > truncateSize) {
        return text.substr(0, truncateSize) + "...";
    }
    return text;
};

export const capitalizeFirstWord = (str) => {
    // Check if the input is null, undefined, or an empty string
    if (!str) {
        return ''; // Or return any other default value you prefer
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formattedTextWithNewLine = (responseText) => {
    return responseText?.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
}

export const englishToArabicNumbers = (numberString) => {
    const arabicNumerals = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

    return numberString.split('').map(char => {
        if (/\d/.test(char)) {
            return arabicNumerals[char];
        }
        return char;
    }).join('');
};