import React from 'react';
import { Link } from 'react-scroll';

const Navbar = ({ offset, headerSelection, navClass, linkClassName }) => (
	<NavComponent
		headerSelection={(e) => headerSelection(e)}
		navClass={navClass}
		offset={offset}
		linkClassName={linkClassName}
	/>
);

const sections = [
	//'Use Cases',
	'Integrations',
	'Product',
	'About',
	'Courses',
	'Pricing',
	//'Testimonials',
	//'FAQ',
	'Contact Us',
	'Try for free',
];

export const NavComponent = ({ offset, headerSelection, navClass, linkClassName }) => (
	<nav className={navClass}>
		{sections.map((section) => (
			<Link
				key={section}
				to={section}
				smooth={true}
				onClick={() => headerSelection(section)}
				className={
					offset >= '1185' && offset <= '1887' ?
						section === 'Features' ?
							(linkClassName + ' color-white')
							:
							linkClassName
						:
						offset >= '1887' && offset <= '2437' ?
							section === 'Integrations' ?
								(linkClassName + ' color-white')
								:
								linkClassName
							:
							offset >= '2437' && offset <= '3905' ?
								section === 'Product' ?
									(linkClassName + ' color-white')
									:
									linkClassName
								:
								offset >= '5017' && offset <= '5840' ?
									section === 'Pricing' ?
										(linkClassName + ' color-white')
										:
										linkClassName
									:
									offset >= '5840' && offset <= '6910' ?
										section === 'Testimonials' ?
											(linkClassName + ' color-white')
											:
											linkClassName
										:
										offset >= '6910' && offset <= '7308' ?
											section === 'FAQ' ?
												(linkClassName + ' color-white')
												:
												linkClassName
											:
											offset >= '7308' ?
												section === 'Contact Us' ?
													(linkClassName + ' color-white')
													:
													linkClassName
												:
												linkClassName


				}
			>
				{section}
			</Link>
		))}
	</nav >
);
export default Navbar;
