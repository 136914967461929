import './App.scss';
import Main from './components/Main';
import Steps from './components/Steps';
import Demo from './components/Demo';
import LearnerCyntric from './components/LearnerCyntric';
import Team from './components/Team';
import Pricing from './components/Pricing';
import Reviews from './components/Reviews';
import WhatWeWork from './components/WhatWeWork';
import Channels from './components/Channels';
import MyFaq from './components/MyFaq';
import Contact from './components/Contact';
import Footer from './components/Footer';
import MobileFooter from './components/MobileFooter';
import { useWindowWidthAndHeight } from './components/utils/useWindowWidthAndHeight';
import MainMobile from './components/MainMobile';
import AOS from 'aos';
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './assets/img/logo.png';
import 'aos/dist/aos.css';
//import FloatingWhatsApp from 'react-floating-whatsapp';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-65CNZJQJZ0"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App({ position }) {
	const [cPosition, setPosition] = useState(null);
	const [width] = useWindowWidthAndHeight();

	const [home, setHome] = useState(null);
	const [featuresRef, setFeaturesRef] = useState(null);
	const [productRef, setProductRef] = useState(null);
	const [integrationsRef, setIntegrationsRef] = useState(null);
	const [pricingRef, setPricingRef] = useState(null);
	const [aboutRef, setAboutRef] = useState(null);
	const [teamRef, setTeamRef] = useState(null);
	const [testemonialsRef, setTestemonialsRef] = useState(null);
	const [myfaqRef, setMyFaqRef] = useState(null);
	const [contactUsRef, setContactUsRef] = useState(null);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		setPosition(position);
	}, [position]);

	useEffect(() => {
		if (window.localStorage.getItem('i18nextLng') !== 'en') {
			i18n.changeLanguage('en');
		}
	}, []);

	useEffect(() => {
		const handleSmoothScroll = () => {
			var offset = 50; // sticky nav height
			if (cPosition === 'Home') {
				home.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
				setPosition(null);
			}
			if (cPosition === 'Use Cases') {
				window.scroll({ top: (featuresRef.current.offsetTop - offset), left: 0, behavior: 'smooth' });
				setPosition(null);
			}
			if (cPosition === 'Integrations') {
				window.scroll({ top: (integrationsRef.current.offsetTop - 120), left: 0, behavior: 'smooth' });
				setPosition(null);
			}
			if (cPosition === 'Product') {
				window.scroll({ top: (productRef.current.offsetTop - 90), left: 0, behavior: 'smooth' });
				setPosition(null);
			}
			if (cPosition === 'About') {
				window.scroll({ top: (aboutRef.current.offsetTop - offset), left: 0, behavior: 'smooth' });
				setPosition(null);
			}
			if (cPosition === 'Pricing') {
				window.scroll({ top: (pricingRef.current.offsetTop - 130), left: 0, behavior: 'smooth' });
				setPosition(null);
			}
			if (cPosition === 'Testimonials') {
				window.scroll({ top: (testemonialsRef.current.offsetTop - 70), left: 0, behavior: 'smooth' });
				setPosition(null);
			}
			if (cPosition === 'FAQ') {
				window.scroll({ top: (myfaqRef.current.offsetTop - 150), left: 0, behavior: 'smooth' });
				setPosition(null);
			}
			if (cPosition === 'Contact Us') {
				window.scroll({ top: (contactUsRef.current.offsetTop - offset), left: 0, behavior: 'smooth' });
				setPosition(null);
			}
			if (cPosition === 'Try for free') {
				window.scroll({ top: (contactUsRef.current.offsetTop - offset), left: 0, behavior: 'smooth' });
				setPosition(null);
			}
			setPosition(null);
		};

		handleSmoothScroll();
	}, [pricingRef, aboutRef, contactUsRef, myfaqRef, home, teamRef, testemonialsRef, featuresRef, productRef, integrationsRef, cPosition]);

	useEffect(() => {
		AOS.init();
	}, []);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname + window.location.search);
	}, []);
	// useEffect(() => {
	// console.log(('Current scroll from the top: ' + window.clientHeight))
	// })
	return (
		<div className='App'>
			{width <= 1000 && <MainMobile home={(e) => setHome(e)} width={width} />}
			{width >= 1001 && <Main home={(e) => setHome(e)} width={width} />}
			<WhatWeWork featuresRef={(e) => setFeaturesRef(e)} />
			<LearnerCyntric featuresRef={(e) => setFeaturesRef(e)} width={width} />
			<Channels integrationsRef={(e) => setIntegrationsRef(e)} width={width} />
			<Steps width={width} productRef={(e) => setProductRef(e)} />
			<Demo width={width} />
			<Team aboutRef={(e) => setAboutRef(e)} />
			<Pricing pricingRef={(e) => setPricingRef(e)} width={width} />
			<Reviews testemonialsRef={(e) => setTestemonialsRef(e)} width={width} />
			<MyFaq myfaqRef={(e) => setMyFaqRef(e)} />
			<Contact contactUsRef={(e) => setContactUsRef(e)} width={width} />
			{width > 500 && <Footer />}
			{width <= 501 && <MobileFooter />}
			{/*<FloatingWhatsApp
				phoneNumber='+971522088516'
				accountName='eFlow'
				allowClickAway
				avatar={"/eflow_logo_square.png"}
				notification
				notificationSound
				notificationDelay={30000}
				defaultMessage={`Hello, I have a query`}
			/>*/}

		</div>
	);
}

export default App;
