import React from 'react';
import './About.scss';
import Footer from './Footer';
import img from '../assets/img/about/about_stats.png';
import MobileFooter from './MobileFooter';
import { staticBaseURL } from './config';
import Team from './Team';

const images = ["team01.png", "team02.png", "team03.png", "team04.png", "team05.png", "team06.png"];

const About = ({ width }) => {
    return (
        <div>
            <section className="about-section">
                <div className="about-header">
                    <h2 className='about-title'>About</h2>
                </div>
                <div className="about-container">
                    <h1 className='about-txt-header'>👋 Who Are We</h1>
                    <br />
                    <p className='about-txt'>
                        When the COVID-19 pandemic started in March of 2020, we discovered a significant gap in the way education was being distributed across marginalized communities around the world.
                    </p>
                    <p className='about-txt'>
                        This presented an enormous opportunity for our team to build, test and implement unique solutions that would support organizations providing critical educational content and tools for their beneficiaries.
                    </p>
                    <p className='about-txt'>
                        According to a recent study by UNICEF, there are at least 463 Million or 31% of school children worldwide who cannot be reached by digital and broadcast remote learning programs enacted to encounter school closures.
                    </p>
                    <p className='about-txt'>
                        Our team of experts have an extensive background working directly with administrators, instructors and learners from across the world in providing best practices when it comes to learning online.
                    </p>
                    <p className='about-txt'>
                        We also believe that no learner should be left behind when it comes to learning online, regardless of their economic or social status.
                    </p>
                    <p className='about-txt'>
                        Over the years we have acquired valuable knowledge on many of the challenges and issues that 100+ educational institutions face and provided state-of-the-art solutions to address them.
                    </p>

                    <h1 className='about-txt-header'>💫 Our Philosophy</h1>
                    <br />
                    <p className='about-txt'>
                        We believe that education should not just be limited to a certain curriculum, but is actually meant for learners to grow and develop on multiple aspects.
                    </p>
                    <p className='about-txt'>
                        Learners are not as much as students, who find themselves bound to what is told alone and separate their educational life to any practical and personal use. Learners are in fact the only ones who we believe to know most about themselves, their community, and their relationships with others. By that, a learner becomes what we call a “life-long learner”, a growing member in society and individual to better the world.
                    </p>
                    <p className='about-txt'>
                        For that, we believe that learners are in fact the center of all: They are those who start questions, seek information and knowledge, and try their best to build on what they have collected of said knowledge to be masters in finding solutions.
                    </p>

                    <h1 className='about-txt-header'>🧠 The science behind eFlow</h1>
                    <br />
                    {/* <p className='about-txt'>
                        “We are much closer here”: exploring the use of WhatsApp as a learning environment in a secondary school mathematics class <a href='https://link.springer.com/article/10.1007/s10984-021-09371-0#auth-F__Canan-Durgungoz' target='_blank'>read more</a>
                    </p>
                    <p className='about-txt'>
                        The University of Pennsylvania’s Behavior Change for Good Initiative Partners with Walmart on Research Study to Identify Communications that Encourage Vaccinations <a href='https://news.wharton.upenn.edu/press-releases/2020/11/the-university-of-pennsylvanias-behavior-change-for-good-initiative-partners-with-walmart-on-research-study-to-identify-communications-that-encourage-vaccinations/' target='_blank'>read more</a>
                    </p>
                    <p className='about-txt'>
                        Learning by Degrees <a href='https://www.harvardmagazine.com/2009/11/spaced-education-boosts-learning' target='_blank'>read more</a>
                    </p>
                    <p className='about-txt'>
                        Stanford 'tips-by-text' program helps boost literacy in preschoolers, study finds <a href='https://news.stanford.edu/news/2014/november/texting-literacy-tips-111714.html' target='_blank'>read more</a>
                    </p> */}
                    <div className='science-container'>
                        <div className='science-container-div'>
                            <div className='science-container-img'>
                                <img src={'https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/logo/mit_logo.png'} />
                            </div>
                            <p className='about-txt'>
                                The promise of using WhatsApp for low-tech distance learning
                            </p>
                            <a href='https://news.mit.edu/2020/mit-study-promise-of-whatsapp-for-low-tech-distance-learning-0806' target='_blank' className='about-txt text-center'>
                                Open Research
                            </a>
                        </div>
                        <div className='science-container-div'>
                            <div className='science-container-img'>
                                <img src={'https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/logo/stanford_logo.png'} />
                            </div>
                            <p className='about-txt'>
                                Stanford 'tips-by-text' program helps boost literacy in preschoolers, study finds
                            </p>

                            <a href='https://news.stanford.edu/news/2014/november/texting-literacy-tips-111714.html' target='_blank' className='about-txt text-center'>
                                Open Research
                            </a>
                        </div>
                        <div className='science-container-div'>
                            <div className='science-container-img'>
                                <img src={'https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/logo/harvard_logo.png'} />
                            </div>
                            <p className='about-txt'>
                                Learning by Degrees [Spaced learning]
                            </p>

                            <a href='https://www.harvardmagazine.com/2009/11/spaced-education-boosts-learning' target='_blank' className='about-txt text-center'>
                                Open Research
                            </a>
                        </div>
                        <div className='science-container-div'>
                            <div className='science-container-img'>
                                <img src={'https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/logo/upenn_logo.png'} />
                            </div>
                            <p className='about-txt'>
                                Research Study to Identify Communications that Encourage Vaccinations
                            </p>

                            <a href='https://news.wharton.upenn.edu/press-releases/2020/11/the-university-of-pennsylvanias-behavior-change-for-good-initiative-partners-with-walmart-on-research-study-to-identify-communications-that-encourage-vaccinations/' target='_blank' className='about-txt text-center'>
                                Open Research
                            </a>
                        </div>
                    </div>
                </div>
            </section>
            <div className='about-images-container'>
                <h1 className='about-txt-header'>🤝 Supported by</h1>
                <br />
                <div className='images-container'>
                    {images.map((img, idx) => (
                        <div key={idx}>
                            <img className='slider-image-team' key={idx} src={staticBaseURL + img} alt={img} />
                        </div>
                    ))}
                </div>
            </div>
            {width > 480 && <Footer />}
            {width < 480 && <MobileFooter />}
        </div>
    );
};

export default About;
