import './Team.scss';
//import teamPartners from '../assets/img/team-partners.png';
//import teamImage from '../assets/img/elements-09.png';

import AosDiv from './utils/AosDiv';
import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import img01 from '../assets/img/team/wsa_logo_white.png';
import img02 from '../assets/img/team/MIT_solve.png';
import img03 from '../assets/img/team/IBM.png';
import img04 from '../assets/img/team/VilCap.png';
import img05 from '../assets/img/team/seedstars.png';
import img06 from '../assets/img/team/smart_esa_white.png';
import Slider from 'react-slick';
import { staticBaseURL } from './config';

//const teamImage = "https://www.eflow.app/eflow_landing_files/img/student_focus_f.jpg"

const images = ["team01.png", "team02.png", "team03.png", "team04.png", "team05.png", "team06.png"];

const Team = ({ aboutRef }) => {
	const ref = useRef(null);

	useEffect(() => {
		aboutRef(ref);
	}, [aboutRef]);

	const { t, i18n } = useTranslation();
	const [ImageIndex, setImageIndex] = useState(0);
	const [rtl, setRtl] = useState(false);

	useEffect(() => {
		{ i18n.language === "ar" ? setRtl(true) : setRtl(false) }
	}, [i18n.language])

	const TeamSettings = {
		dots: false,
		infinite: true,
		autoplay: false,
		speed: 100,
		slidesToShow: 6,
		slidesToScroll: 1,
		focusOnSelect: i18n.language === "ar" ? true : false,
		centerMode: false,
		arrows: false,
		beforeChange: (current, next) => setImageIndex(next),
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: false
				}
			},
			{
				breakpoint: 750,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
					dots: false,
					focusOnSelect: false,
				}
			},
			{
				breakpoint: 500,
				settings: {
					focusOnSelect: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					dots: false,
					infinite: true,
					autoplay: false,
					speed: 500,
				}
			},
			{
				breakpoint: 320,
				settings: {
					focusOnSelect: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true,
					dots: false,
					infinite: true,
					autoplay: false,
					speed: 500,
				}
			}
		],
	};
	return (
		<section ref={ref} id="team-section" className='team-section-container'>
			<div className='team-section'>
				{/*<img className='teamImage' src={teamImage} alt='' />*/}
				<div className='team-section-title'>
					<h2 className='team-title COMMON_TITLE_BOLD'>{t('behind_this_platform')}</h2>
					{/* <h2 className='team-sub-title'>{t('about_us')}</h2> */}

					<h3 className='team-description'>
						{t('team_txt')}
					</h3>
				</div>
				{/* <img src={teamPartners} alt='' /> */}
				{/* <Slider {...TeamSettings} className={'team-slider'} > */}
				<div className='images-container'>
					{images.map((img, idx) => (
						<div key={idx}>
							<img className='slider-image-team' key={idx} src={staticBaseURL + img} alt={img} />
						</div>
					))}
				</div>

				{/*<p className='text-read-more'>Read more about the science behind eFlow</p>*/}

				<button href='/#' className='read-more-btn'
			onClick={()=>{
				// window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' });
				window.location.href = "/about";
			}}>
				{'Read more'}
			</button>
				{/* </Slider> */}
			</div>
		</section>
	);
};

export default Team;
