import './Pricing.scss';
import pricingBg from '../assets/img/section-bg.png';
import bronzeIcon from '../assets/img/price-bronze.png';
import silverIcon from '../assets/img/price-silver.png';
import goldIcon from '../assets/img/price-gold.png';
import AosDiv from './utils/AosDiv';
import { useRef, useEffect } from 'react';
import { t } from 'i18next';
import {staticBaseURL} from './config';

const Pricing = ({ pricingRef, width }) => {
	const ref = useRef(null);

	useEffect(() => {
		pricingRef(ref);
	}, [pricingRef]);

	return (
		<section ref={ref} id='pricing-section'>
			<AosDiv dataAos='fade-down'>
				<center>
					<h2 className='pricing-title COMMON_TITLE_BOLD'>{t('prices_title')}</h2>
				</center>
			</AosDiv>
			<div className='pricing-section-container'>
				<div className='pricing-section'>
					{width > 480 && <img className='pricingBg' src={staticBaseURL + "price_bg.png"} alt='' />}
					<div className='pricing-container'>
						<div className='cases'>
							<AosDiv dataAos='fade-up' className="pricing-div">
								<div className='pricing-card pricing-card-side'>
									<div className='main-blue bronze'>
										<p className='pricing-type'>{t('bronze')}</p>
										<img src={staticBaseURL + "bronze.png"} alt='Bronze' />
										<p className='price'>
											$4
											<br />
											<br /> <span className='learner'>learner/month</span>
										</p>
									</div>
									<div className='description-access'>
										<div className='pricing-description'>
											<ul>
												<li>{t('Up_to_4_free_educator_accounts')}</li>
												<li>{t('WhatsApp_shared_number')}</li>
												<li>{t('Advanced_insights_and_analytics')}</li>
												<li>+10,000 {t('messages_per_month')}</li>
												<li>Email technical support</li>
											</ul>
										</div>
										<button className='request'
											onClick={
												() => {
													window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' });
												}
											}
										>
											{t('Request Demo')}
										</button>
									</div>
								</div>
							</AosDiv>
							<AosDiv dataAos='fade-down' className="pricing-div">
								<div className='pricing-card pricing-card-side'>
									<div className='centre-orange'>
										<p>{t('Recommended')}</p>
									</div>
									<div className='main-blue silver'>
										<p className='pricing-type'>{t('silver')}</p>
										<img src={staticBaseURL + "silver.png"} alt='Bronze' />
										<p className='price'>
											$10
											<br />
											<br /> <span className='learner'>learner/month</span>
										</p>
									</div>
									<div className='description-access'>
										<div className='pricing-description'>
											<ul>
												<li>{t('Up_to_12_free_educator_accounts')}</li>
												<li>{t('WhatsApp_dedicated_number')}</li>
												<li>{t('Advanced_insights_and_analytics')}</li>
												<li>+100,000 {t('messages_per_month')}</li>
												<li>Email & Phone support</li>
											</ul>
										</div>
										<button className='request'
											onClick={
												() => {
													window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' });
												}
											}
										>
											{t('Request Demo')}
										</button>
									</div>
								</div>
							</AosDiv>
							<AosDiv dataAos='fade-up' className="pricing-div">
								<div className='pricing-card pricing-card-side'>
									<div className='main-blue'>
										<p className='pricing-type'>{t('gold')}</p>
										<img src={staticBaseURL + "gold.png"} alt='Bronze' />
										<p className='price'>
											{t('Contact')}
											<br />
											<br /> <span className='learner'> {t('us')}</span>
										</p>
									</div>
									<div className='description-access'>
										<div className='pricing-description'>
											<ul>
												<li>Unlimited educator accounts</li>
												<li>{t('WhatsApp_dedicated_number')}</li>
												<li>{t('Advanced_insights_and_analytics')}</li>
												<li>{t('Data_export_in_different_formats')}</li>
												<li>+5,000,000 {t('messages_per_month')}</li>
												<li>Email & Phone support</li>
											</ul>
										</div>
										<button className='request'
											onClick={
												() => {
													window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' });
												}
											}
										>
											{t('Request Demo')}
										</button>
									</div>
								</div>
							</AosDiv>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Pricing;
