import './LearnerCyntric.scss';
// import img01 from '../assets/img/learner-cyntric/1.png';
// import img02 from '../assets/img/learner-cyntric/2.png';
// import img03 from '../assets/img/learner-cyntric/3.png';
// import img04 from '../assets/img/learner-cyntric/elements-07.png';
import child from '../assets/img/girl.png';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { staticBaseURL } from './config';

const LearnerCyntric = ({ width, featuresRef }) => {

    const ref = useRef(null);

    useEffect(() => {
        featuresRef(ref);
    }, [featuresRef]);

    const { t } = useTranslation();

    return (
        <section id='add-ons-container' ref={ref} className="learner-section-container">
            {/* <div className='learner-section-left-container'>
                <div>
                    <h1 className="learner-section-title COMMON_TITLE_BOLD">
                        {t('learner_centric_title')}
                    </h1>
                    <p className="learner-section-paragraph">
                        {t('youth_and_adults')}
                    </p>
                </div>
                <div className='learner-section-header'>
                    <div className='learner-section-sector'>
                        <div className='learner-sector-container'>
                            <img className='learner-sector-img' src={staticBaseURL + "learner01.png"} alt="" />
                            <p className='learner-sector-txt'>{t('adaptive')}<br />{t('learning')}</p>
                        </div>
                        <div className="learner-sector-container">
                            <img className='learner-sector-img' src={staticBaseURL + "learner02.png"} alt="" />
                            <p className='learner-sector-txt'>{t('social')}<br />{t('learning')}</p>
                        </div>
                    </div>
                    <div className='learner-section-sector'>
                        <div className='learner-sector-container'>
                            <img className='learner-sector-img' src={staticBaseURL + "learner03.png"} alt="" />
                            <div className='learner-sector-txt-content'>
                                <p className='learner-sector-txt'>{t('learner')}</p>
                                <p className='learner-sector-txt'>{t('psycographic')}</p>
                                <p className='learner-sector-txt'>{t('emotions')}</p>
                            </div>
                        </div>
                        <div className="learner-sector-container">
                            <img className='learner-sector-img' src={staticBaseURL + "learner04.png"} alt="" />
                            <p className='learner-sector-txt'>{t('conversational')}<br />{t('natural')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="learner-section-right-container">
                <img className='learner-sector-img' src={staticBaseURL + "girl.png"} alt="" />
            </div> */}
            <div className='lc-header'>
                <p className='COMMON_TITLE_BOLD lc-title'>{t('use_cases')}</p>
                <p className='lc-sub-title'>{t('learn_more_how_flow_can_enhance_your_life')}</p>
            </div>
            <div className='lc-card-group'>
                <div className='lc-card'
                    onClick={
                        () => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' }); }
                    }
                >
                    <div className='lc-img-container'>
                        <img className='lc-img' src={staticBaseURL + 'use_case_1.png'} />
                    </div>
                    <div className='lc-action-container'>
                        <p className='lc-txt'>{t('corporate_learning')}<br />{t('and_development')}</p>
                        <a className='lc-button' onClick={
                            () => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' }); }
                        }>
                            {t('learn_more')}
                        </a>
                    </div>
                </div>
                <div className='lc-card'
                    onClick={
                        () => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' }); }
                    }
                >
                    <div className='lc-img-container'>
                        <img className='lc-img' src={staticBaseURL + 'use_case_2.png'} />
                    </div>
                    <div className='lc-action-container'>
                        <p className='lc-txt'>{t('youth')}<br />{t('learning')}</p>
                        <a className='lc-button' onClick={
                            () => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' }); }
                        }>
                            {t('learn_more')}
                        </a>                </div>
                </div>
                <div className='lc-card'
                    onClick={
                        () => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' }); }
                    }
                >
                    <div className='lc-img-container'>
                        <img className='lc-img' src={staticBaseURL + 'use_case_3.png'} />
                    </div>
                    <div className='lc-action-container'>
                        <p className='lc-txt'>{t('lead_generation')}<br />{t('and_data_collection')}</p>
                        <a className='lc-button' onClick={
                            () => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' }); }
                        }>
                            {t('learn_more')}
                        </a>
                    </div>
                </div>
            </div>
        </section >
    );
}

export default LearnerCyntric;
