export const TRANSLATIONS_AR = {
	future: 'مستقبل',
	conversational: 'تحادثي',
	mobile: 'التعلم المتنقل',
	frictionless: 'التعلم عبر الإنترنت بدون احتكاك وجذاب وذكي',
	bookADemo: 'احجز عرضًا',
	watch_video: 'شاهد الآن',
	our_clients: 'زملائنا',
	learner_centric_title: 'منصة تعليمية تتمحور حول المتعلم',
	youth_and_adults: 'للشباب والكبار',
	adaptive: 'التكيف',
	social: 'اجتماعي',
	learner: 'المتعلم',
	psycographic: 'علم النفس',
	emotions: 'والعواطف',
	learn_more: 'يتعلم أكثر',
	corporate_learning: 'التعلم المؤسسي',
	and_development: '& تطوير',
	youth: 'شباب',
	learning: 'تعلُّم',
	lead_generation: 'تقود الجيل',
	and_data_collection: '& جمع البيانات',
	use_cases: 'استخدم حالات',
	learn_more_how_flow_can_enhance_your_life: 'تعرف على المزيد كيف يمكن لـ eFlow أن يعزز أهدافك.',
	natural: 'طبيعي',
	seamlessly: 'بسلاسة',
	available: ' متاح',
	highligh_integrable: '& متكامل للغاية',
	various_apps: 'مع تطبيقات مختلفة',

	beginner: 'مبتدئ',
	intermediate: 'متوسط',
	advanced: 'متقدم',
	expert: 'خبير',
	professional: 'محترف',
	specialized: 'متخصص',
	minutes: 'دقيقة',
	Enroll: "سجل",

	create_now: 'اصنع الان',
	create_educational_experience: 'خلق الخبرات التعليمية',
	in_3_easy_steps: 'في 3 خطوات سهلة',
	step01_title: '1. قم بإنشاء التدفق الخاص بك',
	step01_description: 'تدعم الرسائل المرسلة العديد من الصور والملاحظات الصوتية والروابط والمستندات وتتضمن +10 قوالب تقييم (MCQ. السحب والإفلات، True False...)',
	step02_title: '2. ادفع للمتعلمين',
	step02_description: "يتواصل برنامج الدردشة الآلي الخاص بـ eFlow على WhatsApp أو Facebook Messenger أو Slack أو أي تطبيق تواصل آخر مع كل متعلم، ويحلل ردودهم ويرد عليهم.",
	step03_title: '3. تتبع النتائج وتحليلها',
	step03_description: 'السماح للمسؤولين بالحصول على تحليلات حول عدد الدورات التدريبية المكتملة لكل طالب أو مجموعة بناءً على عدد من المعلومات السكانية مثل البلد والعمر وما إلى ذلك.',
	demo01: "لنأخذ دقيقتين ونكتشف ذلك",
	demo02: 'إذا كان eFlow مناسبًا لك',
	behind_this_platform: 'حول التدفق الإلكتروني',
	about_us: 'معلومات عنا',
	team_txt: 'علماء التكنولوجيا والمعلمون لديهم رؤية مشتركة لضمان التعليم الجيد المنصف والشامل وتعزيز فرص التعلم مدى الحياة للجميع.',
	prices_title: 'أسعار وحزم معقولة',
	prices_learner: '/المتعلم',
	bronze: 'برونزية',
	silver: 'فضة',
	gold: 'ذهب',
	Minimum_30_learners: 'الحد الأدنى 30 متعلمًا',
	Up_to_4_free_educator_accounts: 'ما يصل إلى 4 حسابات مجانية للمعلمين',
	WhatsApp_shared_number: 'رقم الواتساب المشترك',
	Telegram_shared_number: 'رقم مشترك في تيليجرام',
	Live_tracking: "تتبع مباشر",
	Advanced_insights_and_analytics: 'رؤى وتحليلات متقدمة',
	Data_export_in_different_formats: 'تصدير البيانات بتنسيقات مختلفة',
	messages_per_month: 'رسائل شهريا',
	Full_technical_support: 'Full technical support',
	Request_Access: 'Request Access',
	Request_Quote: 'طلب اقتباس',
	Recommended: 'مُستَحسَن',

	Minimum_1000_learners: "الحد الأدنى 1,000 متعلم",
	Up_to_12_free_educator_accounts: "حتى 12 حساب مدرس مجاني",
	WhatsApp_dedicated_number: "رقم WhatsApp مخصص",
	Telegram_dedicated_number: "رقم Telegram مخصص",
	Full_educational_support: "دعم تعليمي كامل",

	Contact: "اتصل",
	us: "بنا",

	Minimum_5000_learners: "الحد الأدنى 5,000 متعلم",
	unlimited_free_educator_accounts: "حسابات مدرس غير محدودة مجانية",
	Dedicated_instructional_designer: "مصمم تعليمي مخصص",
	Hear_from_our_clients: "استمع من عملائنا",
	eFlow_is_loved_by_clients: "eFlow يحظى بحب العملاء!",
	take_2_minutes: "لنأخذ 2 دقيقة ونعرف",
	out_if_Flow_is_right_for_you: "ما إذا كان eFlow مناسبًا لك",

	Contact_Us: "اتصل بنا",
	We_are_here_to_help: "نود أن نسمع منك",
	email: "البريد الإلكتروني",
	name: "اسمك",

	Address: "العنوان",
	Address_description: "108 West 13th Street, City of Wilmington, County of New Castle, USA",
	phone: "الهاتف",
	number: "+1 (480) 864-8381",
	send: "إرسال",
	eFlow_enables_interactive_microlearning_around_the_world: "يتيح eFlow التعلم التفاعلي للميكرو على مستوى العالم.",

	About_eFlow: "حول eFlow",
	Our_Team: "فريقنا",
	Portfolio: "محفظتنا",
	Contact_informations: "معلومات الاتصال بنا",

	Features: "الميزات",
	featured_courses: "الدورات المميزة",
	Integrations: "التكاملات",
	Authoring_tools: "أدوات الكتابة",
	Add_ons: "إضافات",

	Legal: "قانوني",
	Terms_and_conditions: "الشروط والأحكام",
	Privacy_policy: "سياسة الخصوصية",
	Copyrights: "حقوق النشر",
	All_rights_reserved: "كل الحقوق محفوظة. تشغيل بواسطة eFlow Technologies Inc.",

	q1: "ما هي اللغات المعتمدة؟",
	ans1: "نحن ندعم الإنجليزية والعربية والفرنسية والإسبانية. يتم دعم المزيد من اللغات بسرعة.",
	q2: "إلى كم عدد المتعلمين الذي يمكنني الوصول إليهم؟",
	ans2: "تبعًا لحزمتك، يمكنك زيادة عدد المتعلمين الذين يمكنك إدارتهم على المنصة.",
	q3: "ماذا يحتاج المتعلمون لتنزيله لبدء التعلم؟",
	ans3: "لا شيء، يجب أن يكون لدى المتعلمين فقط WhatsApp أو Telegram أو Facebook messenger اعتمادًا على منصتك المختارة. لا يلزم تنزيل أي تطبيق آخر.",
	q4: "هل يجب على المعلم أن يكون متصلاً على الإنترنت حتى يتمكن الطلاب من التعلم؟",
	ans4: "لا، يوفر eFlow منصة تعلم غير متزامنة تديرها روبوت تعلمنا التعليمي. يتعين على المربين فقط إضافة المحتوى إلى المنصة وسيكون الروبوت التعليمي التلقائي دائمًا متاحًا لمساعدة المتعلمين في تعلم المحتوى.",
	q5: "ما نوع المحتوى الذي ندعمه؟",
	ans5: "ندعم عددًا متنوعًا من الرسائل من رسائل الوسائط مثل مقاطع الفيديو والصور والرسائل الصوتية إلى الأسئلة متعددة الاختيارات والأسئلة الصحيحة أو الخاطئة والمحتوى التفاعلي H5P.",
	q6: "كيف يمكنني التسجيل؟",
	ans6: "اتصل بنا على hello@eflow.app وسنرسل لك المزيد من التفاصيل لبدء استخدام المنصة.",

	whatsapp_learning: "التعلم عبر WhatsApp",
	to_transform_your: "لتحويل",
	unlock_true: "فتح الإمكانات الحقيقية لقوى العمل الخاصة بك مع المحتوى باللغة الطبيعية الذكي الذي يتم تسليمه عبر WhatsApp",
	try_for_free: "جرب eFlow مجاناً",
	driving_industry: "قيادة، صناعة",
	level_changes: "تغييرات المستوى",
	driving_info_text: "مع نتائج مثبتة عبر الصناعات وعملاء متنوعين ومجالات متعددة.",
	sales_workforce: "القوى العاملة في مجال المبيعات",
	delivery_workforce: "القوى العاملة التسليم",
	logistics_workforce: "القوى العاملة اللوجستية",

	whatsapp: "WHATSAPP",
	break_free: "التحرر من أساليب التدريب المملة. الآن قم بتدريب القوى العاملة لديك على التطبيق الأكثر شعبية.",

	vernacular: "عامية",
	content: "محتوى",
	vernacular_description: "استخدم الآن الذكاء الاصطناعي لبناء تدريب عامي فعال من حيث التكلفة وسهل الفهم.",

	gamification: "اللعب",
	gamification_description: "إنشاء تجربة محفزة وجذابة لفرض إكمال الوحدات بشكل أسرع.",

	real_time: "في الوقت الحالى",
	analytics: "التحليلات",
	real_time_description: "تتبع عدد المستخدمين المسجلين ونسبة إكمال التدريب والتقدم المحرز في تدريب المستخدم والمزيد في الوقت الفعلي.",
	course_name: "اسم الدورة التدريبية",
	price: "سعر"
};
