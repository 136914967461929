import './Footer.scss';
import { t } from 'i18next';
import { staticBaseURL } from './config';

const Footer = () => {
	const handleScroll = (position) => {
		const offset = 50;
		const element = document.getElementById(position);
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = element.getBoundingClientRect().top;
		const elementPosition = elementRect - bodyRect;
		const offsetPosition = elementPosition - offset;

		window.scrollTo({
			top: offsetPosition - 70,
			behavior: 'smooth'
		});
	}
	return (
		<div className='footer-section-container'>
			<div className='footer-section'>
				<div className='footer-links'>
					<div className='link'>
						<img className='title link-image' src={staticBaseURL + "eflow_logo.png"} alt='' />
						<p className='links'>{t('eFlow_enables_interactive_microlearning_around_the_world')}</p>
					</div>
					<div className='link'>
						<p className='title'>{t('About_eFlow')}</p>
						<ul className='links'>
							<li>
								<a onClick={() => {
									handleScroll('team-section')
								}}>
									{t('Our_Team')}
								</a>
							</li>
							<li>
								<a href='/about' target="_blank">
									{t('Portfolio')}
								</a>
							</li>
							<li><a
								onClick={() => {
									handleScroll('contact-container');
								}}>
								{t('Contact_informations')}
							</a>
							</li>
						</ul>
					</div>
					<div className='link'>
						<p className='title'>{t('use_cases')}</p>
						<ul className='links'>
							<li>
								<a
									onClick={() => {
										handleScroll('integration-container');
									}}>
									{t('Integrations')}
								</a>
							</li>
							<li>
								<a
									onClick={() => {
										handleScroll('tools-container');
									}}>
									{t('Authoring_tools')}
								</a>
							</li>
							<li>
								<a
									onClick={() => {
										handleScroll('integration-container');
									}}>
									{t('Add_ons')}
								</a>
							</li>
						</ul>
					</div>
					<div className='link'>
						<p className='title'>{t('Legal')}</p>
						<ul className='links'>
							<li><a href='/privacy' target="_blank">{t('Terms_and_conditions')}</a></li>
							<li><a href='/privacy' target="_blank">{t('Privacy_policy')}</a></li>
						</ul>
					</div>
				</div>
				<div className='socials'>
					<a href='https://www.linkedin.com/company/eflowapp' target='_blank'><img className='socialsimg' src={staticBaseURL + "linkedin.png"} alt='' /></a>
					<a href="mailto:hello@eflow.app" target='_blank'><img className='socialsimg' src={staticBaseURL + "email_white.png"} alt='' /></a>
					<a href="https://www.facebook.com/eflowapp" target="_blank"><img className='socialsimg' src={staticBaseURL + "facebook.png"} alt='' /></a>
					<a href="https://www.instagram.com/eflow.ai/" target="_blank"><img className='socialsimg' src={staticBaseURL + "instagram.png"} alt='' /></a>
				</div>
				<div className='footer-end'>
					<div className='social-copyrights'>
						<p className='copyright'>
							{t('Copyrights')} &copy; {new Date().getFullYear()} {t('All_rights_reserved')}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
