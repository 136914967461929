import React from 'react';
import './WhatsappLearning.scss';
import {useTranslation} from "react-i18next";

const WhatsappLearning = ({width}) => {
    const {t, i18n} = useTranslation();

    return (
        <section className={'bcw-whatsapp-learning-section'}>
            <div className={'whatsapp-learning-content'}>
                <div className={'content-left'}>
                    <div className={'whatsapp-learning-info'}>
                        <h1 className={'whatsapp-learning-title'}>{t('whatsapp')}</h1>
                        <h1 className={'whatsapp-learning-subtitle'}>{t('learning')}</h1>

                        <br/>

                        <h3 className={'whatsapp-learning-description'}>{t('break_free')}</h3>

                        <br/>

                        <a
                            className={'whatsapp-learning-learn-more'}
                            onClick={
                                () => {
                                    window.Calendly.initPopupWidget({url: 'https://calendly.com/eflow-demo'});
                                }
                            }
                        >
                            {t('learn_more')}
                        </a>
                    </div>
                </div>

                <div className={'content-right'}>
                    <img
                        alt='left'
                        className={i18n.language === "en" ? "whatsapp-learning-img" : "whatsapp-learning-img-ar"}
                        src={'https://eflow-static01.s3-us-west-2.amazonaws.com/media/phpp2hwRs.png'}
                    />
                </div>
            </div>
        </section>
    );
};

export default WhatsappLearning;
