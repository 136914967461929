import Footer from './Footer';
import Reviews from "./Reviews";
import Driving from "./Driving";
import Contact from "./Contact";
import './WhatsappTraining.scss';
import AosDiv from "./utils/AosDiv";
import React, {useRef} from 'react';
import WhatWeWork from "./WhatWeWork";
import Vernacular from "./Vernacular";
import MobileFooter from './MobileFooter';
import ClientsSlider from "./ClientsSlider";
import {useTranslation} from "react-i18next";
import WhatsappLearning from "./WhatsappLearning";
import WhatsappTrainingMain from "./WhatsappTrainingMain";
import WhatsappTrainingMainMobile from "./WhatsappTrainingMainMobile";
import {useWindowWidthAndHeight} from "./utils/useWindowWidthAndHeight";
import Gamification from "./Gamification";
import RealTimeAnalytics from "./RealTimeAnalytics";

const WhatsappTraining = (props) => {
    const [width] = useWindowWidthAndHeight(),
        ref = useRef(null),
        {t, i18n} = useTranslation();

    document.body.dir = i18n.dir();

    return (
        <main ref={ref}>
            {width <= 1000 && <WhatsappTrainingMainMobile width={width} />}
            {width >= 1001 && <WhatsappTrainingMain width={width} />}

            <AosDiv dataAos='' className='bcw-main-clients'>
                <h3>{t('our_clients')}</h3>

                <ClientsSlider/>
            </AosDiv>

            <WhatWeWork
                featuresRef={(e) => {
                    // setFeaturesRef(e)
                }}
            />

            <Driving/>

            <WhatsappLearning/>

            <Vernacular/>

            <Gamification/>

            <RealTimeAnalytics/>

            <Reviews
                width={width}
                testemonialsRef={(e) => {
                    // TODO check this
                    // setTestimonialsRef(e)
                }}
            />

            <Contact
                width={width}
                contactUsRef={(e) => {
                    // TODO check this
                    // setContactUsRef(e)
                }}
            />

            {
                props.width > 480 &&
                <Footer/>
            }

            {
                props.width < 480 &&
                <MobileFooter/>
            }
        </main>
    );
};

export default WhatsappTraining;
