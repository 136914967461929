export default {
    SUCCESSFUL_LOGIN: 'SUCCESSFUL_LOGIN',
    PERFORM_LOGOUT: 'PERFORM_LOGOUT',
    FILL_COUNTRIES: 'FILL_COUNTRIES',
    FILL_COURSE_TYPES: 'FILL_COURSE_TYPES',
    FILL_LANGUAGES: 'FILL_LANGUAGES',
    GROUP_REFRESH: 'GROUP_REFRESH',
    EVENT_REFRESH: 'EVENT_REFRESH',
    ORGANIZATION_REFRESH: 'ORGANIZATION_REFRESH',
    COURSE_REFRESH: 'COURSE_REFRESH',
    CATEGORY_REFRESH: 'CATEGORY_REFRESH',
    SET_FILTER: 'SET_FILTER',
    FILL_CHAT_BUBBLE_DETAILS: 'FILL_CHAT_BUBBLE_DETAILS',
    LOADING: 'LOADING',
    FILL_COURSES: 'FILL_COURSES',
    FILL_GROUPS: 'FILL_GROUPS',
    CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
    GAME_REFRESH: 'GAME_REFRESH',
    FILL_GAMES: 'FILL_GAMES',
    FILL_CATEGORIES: 'FILL_CATEGORIES',
    FILL_ORGANIZATIONS: 'FILL_ORGANIZATIONS'
}
