import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Parent from "./Parent";
import { recaptchaKey } from "./components/config";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
	<GoogleReCaptchaProvider
		reCaptchaKey={recaptchaKey}
		// language="[optional_language]"
		// useRecaptchaNet="[optional_boolean_value]"
		// useEnterprise="[optional_boolean_value]"
		scriptProps={{
			async: false, // optional, default to false,
			defer: false, // optional, default to false
			appendTo: "head", // optional, default to "head", can be "head" or "body",
			nonce: undefined, // optional, default undefined
		}}
	>
		<React.StrictMode>
			<Parent />
		</React.StrictMode>
	</GoogleReCaptchaProvider>,
	document.getElementById("root")
);
