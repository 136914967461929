import './Main.scss';
import './MainMobile.scss';
import AosDiv from './utils/AosDiv';
import { useRef, useEffect } from 'react';
import ClientsSlider from './ClientsSlider';
import rightImage1 from '../assets/img/header/1.png';
import rightImage2 from '../assets/img/header/2.png';
import rightImage3 from '../assets/img/header/3.png';
import rightImage5 from '../assets/img/header/5.png';
import { useTranslation } from 'react-i18next';
import {staticBaseURL} from './config';

const MainMobile = ({ width, home, lang }) => {
    const ref = useRef(null);

    useEffect(() => {
        home(ref);
    }, [home]);

    const { t } = useTranslation();
    return (
        <div className='main-flex-container'>
            <AosDiv className='main-left' dataAos='fade-right'>
                <h1>
                    {t('conversational')}
                    <br />
                    {t('mobile')}
                </h1>
                <img className='main-img-01' src={staticBaseURL + "fb-banner.png"} alt='left' />
                <img className='main-img-02' src={staticBaseURL + "slack-banner.png"} alt='left' />
                <img className='main-img-03' src={staticBaseURL + "ms-teams.png"} alt='left' />
                <img className='main-img-04' src={staticBaseURL + "wa-banner.png"} alt='left' />
                <h3 className='web-text'>{t('frictionless')}</h3>

                <a className='main-demo' onClick={
                    () => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' }); }
                }>{t('bookADemo')}</a>

                {width > 1000 && <br />}
                {/*<a href='/#' className='main-watch'>
                    {t('watch_video')}
            </a>*/}
            </AosDiv>

            <AosDiv dataAos='fade-up' className='main-clients'>
                <h3>Trusted by</h3>
                <ClientsSlider />
            </AosDiv>
        </div>
    );
};

export default MainMobile;