import blue from '../assets/img/quotes01.png';
import purple from '../assets/img/quotes02.png';
import orange from '../assets/img/quotes03.png';
import pink from '../assets/img/quotes04.png';
import green from '../assets/img/quotes05.png';

const ReviewComponent = ({ data, ImageIndex, idx, i18n }) => {
    const mystyle = {
        borderTop: '1px solid ' + data.border_style,
    };
    return (
        <div id={idx} className={idx === ImageIndex && i18n.language !== "ar" ? "reviews slide activeSlide" : "reviews slide"}>
            {/* <AosDiv dataAos='flip-left'> */}
            <div className='reviews-card reviews-card-side'>
                <div className='review-header'>
                    <div className='review-partner'>
                        <img className='review-company' src={data.ngo_logo} alt='' />
                    </div>
                    <div className='review-quotation'>
                        <img className='review-quotes'
                            src={data.quote_tile === 'orange' ? orange :
                                data.quote_tile === 'purple' ? purple :
                                    data.quote_tile === 'pink' ? pink :
                                        data.quote_tile === 'blue' ? blue : 
                                        data.quote_tile === 'green' ? green : blue}
                            alt='' />
                    </div>
                </div>
                <div className='review-content'>
                    {data.quote}
                </div>
                <div className={i18n.language === "en" ? "review-author" : "review-author-ar"} style={mystyle}>
                    <div className='author-image'>
                        <img src={data.profile_pic[Object.keys(data.profile_pic)[0]]} alt='' />
                    </div>
                    <div className='author-details'>
                        <p className='author-name'>{data.name}</p>
                        <p className='author-job'>
                            {data.position}
                        </p>
                        <p className='author-organization'>{data.organization}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default ReviewComponent;