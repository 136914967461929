import './WhatWeWork.scss';
import { useState, useEffect, useRef } from "react";
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import { staticBaseURL } from './config';

const images = [
    { "id": 1214, "name": "zaka_ai_10mins_cover.png" },
    { "id": 1313, "name": "leadership_cover.png" },
    { "id": '', "name": "course01.png" },
    { "id": '', "name": "course02.png" },
    { "id": '', "name": "course03.png" },
    { "id": '', "name": "course04.png" },
    { "id": '', "name": "course05.png" },
    { "id": '', "name": "course06.png" },
    { "id": '', "name": "course07.png" },
    { "id": '', "name": "course08.png" },
    { "id": '', "name": "course09.png" },
    { "id": 1203, "name": "ikigai_cover.png" },
];

const WhatWeWork = () => {
    const { t, i18n } = useTranslation();
    const [ImageIndex, setImageIndex] = useState(0);
    const [rtl, setRtl] = useState(false);
    const [startX, setStartX] = useState(0);
    const [endX, setEndX] = useState(0);
    const startXRef = useRef(0);

    useEffect(() => {
        setRtl(i18n.language === "ar");
    }, [i18n.language]);


    const slickSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        rtl: rtl,
        centerMode: true,
        prevArrow: null,
        nextArrow: null,
        arrows: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ],
        beforeChange: (current, next) => setImageIndex(next),
    };

    const handleMouseDown = (e) => {
        startXRef.current = e.clientX;
    };

    const handleMouseUp = (e, img) => {
        const endX = e.clientX;
        const startX = startXRef.current;

        if (Math.abs(startX - endX) < 50) {
            handleGoToCourse(img);
        }
    };

    const handleGoToCourse = (id) => {
        if (id) {
            window.location.href = `/coursesDetails/${id}`;
        }
    };

    return (
        <div id='portfolio-container' className='Work-container'>
            <section className='section-work' style={{ position: 'relative' }}>
                <p className='COMMON_TITLE_BOLD wc-title'>{t('featured_courses')}</p>
                <img className='work-bg' src={staticBaseURL + "course_bg.png"} alt='' />
                <div className='work-container'>
                    <Slider {...slickSettings}>
                        {images.map((img, idx) => (
                            <div
                                key={idx}
                                className={idx === ImageIndex ? "slide activeSlide" : "slide"}
                                onMouseDown={handleMouseDown}
                                onMouseUp={(e) => handleMouseUp(e, img.id)}
                            >
                                <img className='slider-image' src={staticBaseURL + img.name} alt={img.name} />
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </div>
    );
};

export default WhatWeWork;
