import types from './types.js';

const initialState = {
    language: 'en',
    showLoading: false,
    groupShouldRefresh: false,
    courseShouldRefresh: false,
    eventShouldRefresh: false,
    organizationShouldRefresh: false,
    countries: [],
    filters: {},
    languages: [],
    categories: [],
    courseTypes: [],
    chatDetails: {}
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.FILL_COUNTRIES:
            return { ...state, countries: payload }
        case types.SET_FILTER:
            return { ...state, filters: payload }
        case types.FILL_LANGUAGES:
            return { ...state, languages: payload }
        case types.FILL_CATEGORIES:
            return { ...state, categories: payload }
        case types.FILL_COURSE_TYPES:
            return { ...state, courseTypes: payload }
        case types.GROUP_REFRESH:
            return { ...state, groupShouldRefresh: payload }
        case types.COURSE_REFRESH:
            return { ...state, courseShouldRefresh: payload }
        case types.ORGANIZATION_REFRESH:
            return { ...state, organizationShouldRefresh: payload }
        case types.EVENT_REFRESH:
            return { ...state, eventShouldRefresh: payload }
        case types.FILL_CHAT_BUBBLE_DETAILS:
            return { ...state, chatDetails: payload }
        case types.LOADING:
            return { ...state, showLoading: payload }
        case types.CHANGE_LANGUAGE:
            return { ...state, language: payload }
        default:
            return state;
    }
}
