import './WhatsappTraining.scss';
import React from 'react';
import {useTranslation} from "react-i18next";
import {useWindowWidthAndHeight} from "./utils/useWindowWidthAndHeight";
import {TypeAnimation} from "react-type-animation";

const WhatsappTraining = () => {
    const [width] = useWindowWidthAndHeight(),
        {t, i18n} = useTranslation();

    document.body.dir = i18n.dir();

    return (
        <section className='bcw-main-flex-container'>
            <div className='bcw-main-left'>
                {/*<AosDiv className='main-left' dataAos='fade-right'>*/}
                <h1>
                    {t('whatsapp_learning')}
                    <br/>
                    {t('to_transform_your')}
                </h1>

                <br/>

                <TypeAnimation
                    speed={10}
                    repeat={Infinity}
                    className={'bcw-sales-work'}
                    sequence={[
                        t('sales_workforce') + ' ...',
                        1000,
                        t('delivery_workforce') + ' ...',
                        1000,
                        t('logistics_workforce') + ' ...',
                        1000
                    ]}
                />

                <br/>

                <p className={'bcw-unlock-true'}>{t('unlock_true') + '...'}</p>

                <div>
                    <a
                        className={'bcw-main-demo'}
                        onClick={
                            () => {
                                window.Calendly.initPopupWidget({url: 'https://calendly.com/eflow-demo'});
                            }
                        }
                    >
                        {t('bookADemo')}
                    </a>

                    <a
                        className={'bcw-try-for-free'}
                        onClick={
                            () => {
                                window.Calendly.initPopupWidget({url: 'https://calendly.com/eflow-demo'});
                            }
                        }
                    >
                        {t('try_for_free')}
                    </a>
                </div>

                {width > 1000 && <br/>}
            </div>
            {/*</AosDiv>*/}

            <div className={'bcw-main-container-right'}>
                {/*<AosDiv dataAos='fade' offset='1' className='main-container-right'>*/}
                <div className='bcw-hero-animation-img bcw-main-right'>
                    {/*a1: phpU4CaVa.png*/}
                    {/*a2: phpnPm7RU.png*/}
                    {/*a3: phpRtLIK6.png*/}
                    <img className={i18n.language === "en" ? "bcw-main-img-main" : "bcw-main-img-main-ar"}
                         src={'https://eflow-static01.s3-us-west-2.amazonaws.com/media/phplXeXlE.png'} alt='left'/>

                    {/*<img className={i18n.language === "en" ? "bcw-main-img-01" : "bcw-main-img-01-ar"}*/}
                    {/*     src={'https://eflow-static01.s3-us-west-2.amazonaws.com/media/phpU4CaVa.png'} alt='left'/>*/}
                    {/*<img className={i18n.language === "en" ? "bcw-main-img-02" : "bcw-main-img-02-ar"}*/}
                    {/*     src={'https://eflow-static01.s3-us-west-2.amazonaws.com/media/phpnPm7RU.png'} alt='left'/>*/}
                    {/*/!*<img className={i18n.language === "en" ? "main-img-03" : "main-img-03-ar"}*!/*/}
                    {/*/!*     src={'https://eflow-static01.s3-us-west-2.amazonaws.com/media/phpRtLIK6.png'} alt='left'/>*!/*/}
                    {/*<img className={i18n.language === "en" ? "bcw-main-img-03" : "bcw-main-img-03-ar"}*/}
                    {/*     src={'https://eflow-static01.s3-us-west-2.amazonaws.com/media/phpRtLIK6.png'} alt='left'/>*/}
                </div>
                {/*</AosDiv>*/}
            </div>
        </section>
    );
};

export default WhatsappTraining;
