import React from 'react';
import './WhatsappTrainingMainMobile.scss';
import {useTranslation} from "react-i18next";
import {useWindowWidthAndHeight} from "./utils/useWindowWidthAndHeight";
import {TypeAnimation} from "react-type-animation";

const WhatsappTraining = () => {
    const [width] = useWindowWidthAndHeight(),
        {t, i18n} = useTranslation();

    document.body.dir = i18n.dir();

    return (
        <section className='bcw-main-flex-container'>
            <div className='bcw-main'>
                {/*<AosDiv className='main-left' dataAos='fade-right'>*/}
                <h1>
                    {t('whatsapp_learning')}
                    <br/>
                    {t('to_transform_your')}
                </h1>

                <TypeAnimation
                    speed={10}
                    repeat={Infinity}
                    className={'bcw-sales-work-mobile'}
                    sequence={[
                        t('sales_workforce') + ' ...',
                        1000,
                        t('delivery_workforce') + ' ...',
                        1000,
                        t('logistics_workforce') + ' ...',
                        1000
                    ]}
                />

                <img className={i18n.language === "en" ? "bcw-main-img-main-mobile" : "bcw-main-img-main-mobile-ar"}
                     src={'https://eflow-static01.s3-us-west-2.amazonaws.com/media/phplXeXlE.png'} alt='left'/>

                <p className={'bcw-unlock-true-mobile'}>{t('unlock_true') + '...'}</p>

                <div>
                    <a
                        className={'bcw-main-demo-mobile'}
                        onClick={
                            () => {
                                window.Calendly.initPopupWidget({url: 'https://calendly.com/eflow-demo'});
                            }
                        }
                    >
                        {t('bookADemo')}
                    </a>

                    <a
                        className={'bcw-try-for-free-mobile'}
                        onClick={
                            () => {
                                window.Calendly.initPopupWidget({url: 'https://calendly.com/eflow-demo'});
                            }
                        }
                    >
                        {t('try_for_free')}
                    </a>
                </div>
            </div>
            {/*</AosDiv>*/}
        </section>
    );
};

export default WhatsappTraining;
