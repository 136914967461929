import './MobileFooter.scss';
import logo from '../assets/img/eflow_blue.png';
import linkedinIcon from '../assets/img/elements-19.png';
import emailIcon from '../assets/img/elements-20.png';
import facebookIcon from '../assets/img/elements-21.png';
import instagramIcon from '../assets/img/elements-22.png';
//import { useState, useEffect } from 'react';
import { t } from 'i18next';

const MobileFooter = () => {
	const handleScroll = (position) => {
		const offset = 50;
		const element = document.getElementById(position);
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = element.getBoundingClientRect().top;
		const elementPosition = elementRect - bodyRect;
		const offsetPosition = elementPosition - offset;

		window.scrollTo({
			top: offsetPosition - 70,
			behavior: 'smooth'
		});
	}
	return (
		<div className='footer-mb-section-container'>
			<div className='footer-mb-section'>
				<div className='footer-mb-links'>
					<div className='link'>
						<img className='title link-image' src={logo} alt='' />
						<p className='links'>{t('eFlow_enables_interactive_microlearning_around_the_world')}</p>
					</div>
					<div className='link'>
						<p className='title'>{t('About_eFlow')}</p>
						<ul className='links'>
							<li>
								<a onClick={() => {
									handleScroll('team-section')
								}}>
									{t('Our_Team')}
								</a>
							</li>
							<li>
								<a href='/about' target="_blank">
									{t('Portfolio')}
								</a>
							</li>
							<li><a
								onClick={() => {
									handleScroll('contact-container');
								}}>
								{t('Contact_informations')}
							</a>
							</li>
						</ul>
					</div>
					<div className='link'>
						<p className='title'>{t('use_cases')}</p>
						<ul className='links'>
							<li>
								<a
									onClick={() => {
										handleScroll('integration-container');
									}}>
									{t('Integrations')}
								</a>
							</li>
							<li>
								<a
									onClick={() => {
										handleScroll('tools-container');
									}}>
									{t('Authoring_tools')}
								</a>
							</li>
							<li>
								<a
									onClick={() => {
										handleScroll('integration-container');
									}}>
									{t('Add_ons')}
								</a>
							</li>
						</ul>
					</div>
					<div className='link'>
						<p className='title'>{t('Legal')}</p>
						<ul className='links'>
							<li><a href='/privacy' target="_blank">{t('Terms_and_conditions')}</a></li>
							<li><a href='/privacy' target="_blank">{t('Privacy_policy')}</a></li>
						</ul>
					</div>
				</div>
				<div className='socials'>
					<a href='https://www.linkedin.com/company/eflowapp' target='_blank'><img className='socialsimg' src={linkedinIcon} alt='' /></a>
					<a href="mailto:hello@eflow.app" target='_blank'><img className='socialsimg' src={emailIcon} alt='' /></a>
					<a href="https://www.facebook.com/eflowapp" target="_blank"><img className='socialsimg' src={facebookIcon} alt='' /></a>
					<a href="https://www.instagram.com/eflow.ai/" target="_blank"><img className='socialsimg' src={instagramIcon} alt='' /></a>
				</div>
				<div className='footer-mb-end'>
					<div className='social-copyrights'>
						<p className='copyright'>
							{t('Copyrights')} &copy; {new Date().getFullYear()} {t('All_rights_reserved')}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
export default MobileFooter;
