import './Demo.scss';
import AosDiv from './utils/AosDiv';
import { useTranslation } from 'react-i18next';
import {staticBaseURL} from './config';

const Demo = ({ width }) => {
	const { t } = useTranslation();
	return (
		<AosDiv dataAos='fade' duration='700' className='section-demo'>
			<h2 className='demo-title'>{t('demo01')} {width >= 480 && <br />} <span className='demo-title-span'>{t('demo02')}</span></h2>
			<button href='/#' className='demo-btn'
			onClick={()=>{
				window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' });
			}}>
				{t('bookADemo')}
			</button>
		</AosDiv>
	);
};

export default Demo;
