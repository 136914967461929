import React from 'react';
import './Vernacular.scss';
import {useTranslation} from "react-i18next";

const Vernacular = () => {
    const {t, i18n} = useTranslation();

    return (
        <section className={'bcw-driving-section'}>
            <div className={'bcw-driving-image'}>
                <img
                    alt='left'
                    className={i18n.language === "en" ? "bcw-main-img-01" : "bcw-main-img-01-ar"}
                    src={'https://eflow-static01.s3-us-west-2.amazonaws.com/media/phpDQIXbE.png'}
                />
            </div>

            <div className={'bcw-driving-info'}>
                <h1>{t('vernacular')}</h1>
                <h1 className={'bcw-level_changes'}>{t('content')}</h1>

                <br/>
                <br/>

                <p className={'bcw-driving-info-text'}>{t('vernacular_description')}</p>

                <br/>
                <br/>

                <a
                    className={'bcw-main-demo'}
                    onClick={
                        () => {
                            window.Calendly.initPopupWidget({url: 'https://calendly.com/eflow-demo'});
                        }
                    }
                >
                    {t('learn_more')}
                </a>
            </div>
        </section>
    );
};

export default Vernacular;
