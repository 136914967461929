import { render } from 'react-dom';
import './Signup.scss';

const Signup = () => {
    return (
        <div>
            <p>Hellow</p>
        </div>
    );
}

export default Signup;