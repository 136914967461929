const AosDiv = ({
	children,
	className,
	dataAos = 'fade',
	offset = '1',
	delay = '1',
	duration = '700',
}) => {
	return (
		<div
			data-aos={dataAos}
			data-aos-offset={offset}
			data-aos-delay={delay}
			data-aos-duration={duration}
			data-aos-easing='ease-in-out'
			data-aos-mirror='true'
			data-aos-once='false'
			className={className}
		>
			{children}
		</div>
	);
};

export default AosDiv;
