import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import NewHeader from "./components/header/NewHeader";
import PrivacyPolicy from "./PrivacyPolicy";
import About from "./components/About";
import Courses from "./components/Courses";
import Singup from "./components/Signup";
import { useWindowWidthAndHeight } from "./components/utils/useWindowWidthAndHeight";
import CourseDetails from "./components/CourseDetails";
import { ToastProvider } from "react-toast-notifications";
import WhatsappTraining from "./components/WhatsappTraining";
//import "semantic-ui-css/semantic.min.css";

const Parent = () => {
	const [position, setPosition] = useState("");
	const [width] = useWindowWidthAndHeight();
	return (
		<ToastProvider>
			<Router>
				<NewHeader
					headerSelection={(e) => {
						if (
							window.location.href.includes("privacy") ||
							window.location.href.includes("about") ||
							window.location.href.includes("courses") ||
							window.location.href.includes("signup")
						) {
							window.location.href = "/";
						} else if (e === "About") {
							window.location.href = "/about";
						} else if (e === "Courses") {
							window.location.href = "/courses";
						} else {
							setPosition(e);
						}
					}}
				/>
				<Routes>
					<Route exact path="/" element={<App position={position} />} />
					<Route exact path="/privacy" element={<PrivacyPolicy width={width} />} />
					<Route exact path="/about" element={<About width={width} />} />
					<Route exact path="/courses" element={<Courses width={width} />} />
					<Route exact path="/coursesDetails/:id" element={<CourseDetails width={width} />} />
					<Route exact path="/signup" element={<Singup width={width} />} />
					<Route exact path="/whatsapp-training-blue-collar" element={<WhatsappTraining width={width} />} />
				</Routes>
			</Router>
		</ToastProvider>
	);
};
export default Parent;
