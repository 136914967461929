import './Reviews.scss';
import reviewsBg from '../assets/img/elements-17.png';
import mobileReviewsBg from '../assets/img/elements-17.png';
import AosDiv from './utils/AosDiv';
import jessica from '../assets/img/testemonials/Jessica.png'
import nour from '../assets/img/testemonials/nour.png'
import bechara from '../assets/img/testemonials/bechara.png'
import marwa from '../assets/img/testemonials/marwa.png'
import no_pic from '../assets/img/testemonials/null.png'
//import ri from '../assets/img/ri.png';
import ReviewComponent from './ReviewComponent';
import { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';
import {staticBaseURL} from './config';

import Slider from 'react-slick';

const colors = {
	orange: '#ffab4c',
	blue: '#10b0df',
	purple: '#8869d2',
	pink: '#ffabc8',
	green: '#00b469'
};
const data = [
	{
		name: "Jessica Hanna",
		position: "Programme Coordinator",
		quote: "While the world was struggling to make sense of the new reality of education in the wake of the pandemic, eFlow was amongst the first-movers to provide a fun interactive experience in remote learning",
		border_style: colors.orange,
		organization: "UNICEF GIL Adolescent and Youth Programme",
		quote_tile: "orange",
		ngo_logo: "https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/logo/GUNICEF_logo.png",
		profile_pic: { jessica }
	},
	{
		name: "Nour Awamleh",
		position: "Research and Program Development Coordinator",
		organization: "Queen Rania Foundation",
		quote: "QRF had an ambitious vision to create a free and accessible tool for a parenting education program and eFlow provided a cost-effective solution for that.",
		border_style: colors.pink,
		quote_tile: "pink",
		ngo_logo: "https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/logo/QRF_logo.png",
		profile_pic: { nour }
	},
	{
		name: "Nadine Al Kaderi",
		position: "CFE Field Supervisor",
		quote: "We highly recommend the eFlow learning platform, since it helped us reach our objective during the summer remedial support effectively.",
		border_style: colors.blue,
		organization: "Relief International",
		quote_tile: "blue",
		ngo_logo: "https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/logo/RI_logo.png",
		profile_pic: { no_pic }
	},
	{
		name: "Bechara Ghaoui",
		position: "Deputy Executive Director",
		quote: "We were impressed by their overall commitment throughout our partnership. eFlow has a very talented dedicated team, and their skillful approach has resulted in successful collaborative work.",
		border_style: colors.green,
		organization: "Mentor Arabia",
		quote_tile: "green",
		ngo_logo: "https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/logo/MA_logo.png",
		profile_pic: { bechara }
	},
	{
		name: "Marwa Harb",
		position: "Senior Learning Officer",
		quote: "The technique of microlearning including videos, infographics, images and extra resources helped make the content more gamified and easy to grasp.The concept of mobile learning using eFlow technique solves challenges of learning using low resources and challenges of learning in tough situations.",
		border_style: colors.purple,
		organization: "DOT",
		quote_tile: "purple",
		ngo_logo: "https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/logo/dot_logo.png",
		profile_pic: { marwa }
	}
];

const Reviews = ({ width, testemonialsRef }) => {
	const ref = useRef(null);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		testemonialsRef(ref);
	}, [testemonialsRef]);

	const [ImageIndex, setImageIndex] = useState(0);

	const ResviewSettings = {
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		focusOnSelect: i18n.language === "ar" ? true : false,
		centerMode: true,
		arrows: false,
		beforeChange: (current, next) => setImageIndex(next),
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					infinite: true,
					dots: false
				}
			},
			{
				breakpoint: 750,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 1,
					dots: false,
					focusOnSelect: false,
				}
			},
			{
				breakpoint: 500,
				settings: {
					focusOnSelect: false,
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: false
				}
			}
		],
	};
	return (
		<section ref={ref} className='reviews-section-container'>
			<div className='reviews-section'>
				{width > 480 && <img className='reviewsBg' src={staticBaseURL + "review_bg.png"} alt='' />}
				{width <= 480 && (
					<img className='reviewsBg' src={staticBaseURL + "review_bg.png"} alt='' />
				)}
				<AosDiv dataAos='fade'>
					<p className='reviewsSectionTitle COMMON_TITLE_BOLD'>{t('Hear_from_our_clients')}</p>
					<p className='reviewsSectionSubTitle'>{t('eFlow_is_loved_by_clients')}</p>
				</AosDiv>

				<div className='reviews-testemonials'>
					<Slider {...ResviewSettings} >
						{data.map((row, idx) => (
							<ReviewComponent key={idx} data={row} i18n={i18n} ImageIndex={ImageIndex} idx={idx} />
						))}
					</Slider>
				</div>

				<div className='reviews-demo'>
					<p className='reviews-demo-title'>
						{t('take_2_minutes')} <br /> {t('out_if_Flow_is_right_for_you')}
					</p>
					<br/>
					<br/>
					<div className='reviews-demo-btn'>
						<a className='review-btn' onClick={
							() => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' }); }
						}>{t('bookADemo')}</a>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Reviews;
