import './MyFaq.scss';
import Faq from 'react-faq-component';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect } from 'react';

const MyFaq = ({ myfaqRef }) => {

	const ref = useRef(null);
	const { t } = useTranslation();

	useEffect(() => {
		myfaqRef(ref);
	}, [myfaqRef]);

	const col1 = {
		rows: [
			{
				title: t('q1'),
				content: t('ans1'),
			},
			{
				title: t('q2'),
				content: t('ans2'),
			}, {
				title: t('q3'),
				content: t('ans3'),
			},
		],
	};
	const col2 = {
		rows: [
			{
				title: t('q4'),
				content: t('ans4'),
			}, {
				title: t('q5'),
				content: t('ans5'),
			}, {
				title: t('q6'),
				content: t('ans6'),
			},
		],
	};

	const styles = {
		arrowColor: 'gray',
		transitionDuration: '0.5s',
		timingFunc: 'ease',
		titleTextColor: "blue",
		rowTitleColor: "blue",
		fontWeight: '800'
	};

	const config = {
		animate: true,
		arrowIcon: '+',
		tabFocus: true,
	};

	return (
		<section ref={ref} className='faq-section-container'>
			<div className='faq-section'>
				<h2 className='COMMON_TITLE_BOLD'>FAQ</h2>
				<div className='faq'>
					<Faq data={col1} styles={styles} config={config} />
					<Faq data={col2} styles={styles} config={config} />
				</div>
			</div>
		</section>
	);
};

export default MyFaq;
