import "./CourseDetails.scss";
import Footer from "./Footer";
import General from "./axios/General";
import PaymentForm from "./PaymentForm";
import { Button, Modal } from "semantic-ui-react";
import MobileFooter from "./MobileFooter";
import "react-phone-input-2/lib/style.css";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { loadStripe } from "@stripe/stripe-js";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { useToasts } from "react-toast-notifications";
import PaymentSuccessModal from "./PaymentSuccessModal";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { capitalizeFirstWord, formattedTextWithNewLine, getWithExpiry, setWithExpiry } from '../helper';
import { staticBaseURL, staticBaseURLAPI7, stripePublishableKey } from "./config";
import WhatsApp from '../../src/assets/whatsapp.png';
import Slider from "react-slick";

const CourseDetails = (props) => {
    const { id } = useParams(),
        { addToast } = useToasts(),
        { t } = useTranslation(),
        stripePromise = loadStripe(stripePublishableKey),
        [recaptchaToken, setRecaptchaToken] = useState(""),
        [phoneNumber, setPhoneNumber] = useState("971"),
        [isLoading, setIsLoading] = useState(false),
        [isPageLoading, setIsPageLoading] = useState(true),
        [courseDetail, setCourseDetail] = useState({}),
        [isSentMessage, setIsSentMessage] = useState(false),
        [openOtpModal, setOpenOtpModal] = useState(false),
        [otp, setOtp] = useState(""),
        [ImageIndex, setImageIndex] = useState(0),
        [selectedImage, setSelectedImage] = useState(''),
        [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false),
        [isPaymentFormDisplayed, setIsPaymentFormDisplayed] = useState(true),
        [recaptchaCallbackTriggered, setRecaptchaCallbackTriggered] = useState(false),
        [uniqueLearningObjectives, setUniqueLearningObjectives] = useState([]),
        [rtl, setRtl] = useState(false),
        [isSuceededPaymentModalDisplayed, setIsSuceededPaymentModalDisplayed] = useState(false);

    const startXRef = useRef(0);

    useEffect(() => {
        setIsPaymentFormDisplayed(false);
        setIsSuceededPaymentModalDisplayed(false);

        getCourseDetails();
    }, []);

    const getCourseDetails = () => {
        new General().getCourseDetails({
            id: id
        }, (response) => {
            setCourseDetail(response.body);

            setUniqueLearningObjectives(response.unique_learning_objectives);

            setIsPageLoading(false);
        }, (error) => {
            console.log(error);
            setIsPageLoading(false);
        }
        );
    };

    /**
     * a small checker function to handle the direction of the enroll
     * button and how it will work if user is authenticated/sendotp/submit free course
     */
    const handleSubmitEnroll = async () => {
        setIsLoading(true);
        setIsSentMessage(true);

        if (phoneNumber.length < 5) {
            addToast(t("please_enter_a_valid_phone_number"), {
                autoDismiss: true,
                appearance: "error"
            });
        } else if (!isRecaptchaVerified) {
            // Handle the case when reCAPTCHA is not verified
            addToast(t("please_verify_recaptcha"), {
                autoDismiss: true,
                appearance: "error"
            });
        } else {
            console.log(getWithExpiry("token"));
            if (courseDetail.price > 0 && getWithExpiry("token")) {
                setIsPaymentFormDisplayed(true);
            } else if (getWithExpiry("token")) {
                await handleSubmit();
            } else {
                await sendOtpMessage();
            }
        }
    };

    const excludedCountries = ["Israel"];

    /**
     * Initialize recaptcha token in state if not isset
     *
     * @returns {JSX.Element}
     */
    const handleInitializingRecaptcha = () => {
        if (recaptchaToken.length === 0) {
            return (
                <GoogleReCaptcha
                    onVerify={(token) => {
                        if (!recaptchaCallbackTriggered) {
                            setRecaptchaCallbackTriggered(true);

                            setRecaptchaToken(token);
                            setIsRecaptchaVerified(true);
                        }
                    }}
                />
            );
        }
    }

    /**
     * send an otp to the user and checks if user exist or no, 
     * and if not it creates a user and awaits for the validation
     */
    const sendOtpMessage = async () => {
        try {
            setIsLoading(true);
            setIsSentMessage(true);
            setIsPageLoading(true);

            new General().sendOtpMessage({
                whatsapp_phone_number: "+" + phoneNumber,
                course_id: courseDetail.id
            }, () => {
                addToast(t("please_check_out_whatsapp_for_messages"), {
                    autoDismiss: true,
                    appearance: "success"
                });

                setIsLoading(false);
                setIsPageLoading(false);
                setIsSentMessage(false);
                setOpenOtpModal(true);
            }, (error) => {
                setIsLoading(false);
                setIsSentMessage(false);

                console.log(error);
            }
            );
        } catch (e) {
            setIsLoading(false);
            setIsSentMessage(false);

            console.log("error", e);
        }
    };

    /**
     * submit form when course is free
     * @returns 
     */
    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            setIsSentMessage(true);

            if (!getWithExpiry('token')) {
                return addToast(t("something went wrong"), {
                    autoDismiss: true,
                    appearance: "warning"
                });
            }

            new General().orderCourse({
                recaptcha: recaptchaToken,
                phone_number: phoneNumber,
                course_id: courseDetail.id
            }, () => {
                addToast(t("please_check_out_whatsapp_for_messages"), {
                    autoDismiss: true,
                    appearance: "success"
                });

                setIsLoading(false);
                setIsSentMessage(false);
            }, (error) => {
                setIsLoading(false);
                setIsSentMessage(false);

                console.log(error);
            }
            );
        } catch (e) {
            setIsLoading(false);
            setIsSentMessage(false);

            console.log("error", e);
        }
    };

    const closeModal = () => {
        setIsSuceededPaymentModalDisplayed(false);
    };

    /**
     * submit the otp to check the validation from the backend 
     * and if true it save the token in the local storage
     */
    const handleSubmitOtp = () => {
        try {
            setIsLoading(true);
            new General().submitOtpMessage({
                whatsapp_phone_number: '+' + phoneNumber,
                otp: otp,
                course_id: courseDetail.id,
            }, (response) => {
                // set token in local storage
                setWithExpiry('token', response.token, 3600 * 1000); // set for 1 hour

                if (courseDetail.price > 0) { // check if course has price
                    setIsPaymentFormDisplayed(true);
                } else { // else submit directly
                    handleSubmit();
                }

                setOtp("");
                setIsLoading(false);
                setOpenOtpModal(false);
            }, (error) => {
                let e = error.response.data.errors;

                Object.keys(e).forEach((item) => {
                    addToast(e[item][0], {
                        autoDismiss: true,
                        appearance: "error"
                    });
                });

                setIsLoading(false);
            });
        } catch (error) {
            let e = error.response.data.errors;

            Object.keys(e).forEach((item) => {
                addToast(e[item][0], {
                    autoDismiss: true,
                    appearance: "error"
                });
            });
            setIsLoading(false);
        }
    };

    const slickSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        focusOnSelect: true,
        rtl: rtl,
        centerMode: true,
        prevArrow: null,
        nextArrow: null,
        arrows: false,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 750,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    dots: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: false
                }
            }
        ],
        beforeChange: (current, next) => setImageIndex(next),
    };

    const handleMouseDown = (e) => {
        startXRef.current = e.clientX;
    };

    const handleMouseUp = (e, img) => {
        const endX = e.clientX;
        const startX = startXRef.current;

        if (Math.abs(startX - endX) < 50) {
            handleGoToCourse(img);
        }
    };

    const handleGoToCourse = (img) => {
        if (img) {
            setSelectedImage(img);
        }
    };

    return (
        <>
            <Modal open={openOtpModal} style={{ zIndex: 1000 }}>
                <Modal.Content className="modal-content">
                    <p className="modal-header">We've sent you a one time code on your number +{phoneNumber} via WhatsApp. Please enter the code below to try the course.</p>
                    <input
                        className='modal-input'
                        placeholder={"Enter your code"}
                        type='text'
                        disabled={isLoading}
                        value={otp}
                        onInput={(e) => {
                            if (e.target.value.length <= 5) {
                                setOtp(e.target.value);
                            }
                        }}
                        required
                    />
                    {
                        isLoading
                            ?
                            <div className="details-container-loader" style={{ height: 'unset' }}>
                                <span className="loader"></span>
                            </div>
                            :
                            <Button
                                className="primary-button"
                                onClick={() => {
                                    handleSubmitOtp();
                                }}
                            >
                                Verify
                            </Button>
                    }
                </Modal.Content>
            </Modal>

            {/* temp div to be removed */}
            {/* <div className="details-container">
                <Elements stripe={stripePromise}>
                    <PaymentForm
                        course={courseDetail}
                        phoneNumber={phoneNumber}
                        recaptcha={recaptchaToken}
                        onSucceededPayment={() => {
                            setIsPaymentFormDisplayed(false);
                            setIsSuceededPaymentModalDisplayed(true);
                        }}
                    />
                </Elements>
            </div> */}

            {
                isPageLoading ?
                    <div className="details-container-loader">
                        <span className="loader"></span>
                    </div>
                    :
                    courseDetail && Object.keys(courseDetail).length > 0 ?
                        <div className={`details-container ${courseDetail?.language?.name === 'Arabic' ? 'ar' : ''}`}>
                            {
                                isPaymentFormDisplayed &&
                                <Elements stripe={stripePromise}>
                                    <PaymentForm
                                        course={courseDetail}
                                        phoneNumber={phoneNumber}
                                        recaptcha={recaptchaToken}
                                        onSucceededPayment={() => {
                                            setIsPaymentFormDisplayed(false);
                                            setIsSuceededPaymentModalDisplayed(true);
                                        }}
                                    />
                                </Elements>
                            }

                            {
                                isSuceededPaymentModalDisplayed &&
                                <PaymentSuccessModal onClose={closeModal} />
                            }

                            {
                                !isPaymentFormDisplayed &&
                                <div
                                    className="banner-container"
                                    style={{ backgroundImage: `url('${staticBaseURLAPI7 + courseDetail.image}')` }}
                                >
                                    <div className="overlay-bg"></div>
                                    <div className="right-banner-section">
                                        <h1 className="header name">{courseDetail?.name}</h1>
                                    </div>

                                    <div className="left-banner-section ">
                                        <div className="left-banner-content" style={courseDetail?.language?.name === 'Arabic' ? {
                                            justifyContent: 'flex-start',
                                            direction: 'ltr'
                                        } : {}}>

                                            <PhoneInput
                                                country={"ae"}
                                                autoFormat={false}
                                                value={phoneNumber}
                                                enableSearch={true}
                                                disabled={isSentMessage}
                                                placeholder={"+971"}
                                                excludeCountries={excludedCountries}
                                                onChange={(value) => {
                                                    setPhoneNumber(value);
                                                }}
                                            />

                                            {handleInitializingRecaptcha()}

                                            <Button
                                                loading={isLoading}
                                                disabled={!recaptchaToken || isSentMessage || isLoading || phoneNumber.length === 0}
                                                className={isSentMessage || isLoading || phoneNumber.length === 0 ? "btn-enroll-disabled" : "btn-enroll"}
                                                onClick={() => {
                                                    handleSubmitEnroll();
                                                }}
                                            >
                                                <img className="channel-icon" src={WhatsApp} alt="whatsapp.png" />
                                                Enroll
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                !isPaymentFormDisplayed &&
                                <>
                                    <div className="details-body">
                                        <div className="right-section">
                                            <div className="card">
                                                <h1 className="author-card-title">About The Author</h1>

                                                <div className="author-div">
                                                    <div className="author-img-div">
                                                        {
                                                            courseDetail?.author?.profile_picture ?
                                                                <div className="profile-image"
                                                                    style={{
                                                                        backgroundImage: `url('${staticBaseURLAPI7 + courseDetail.author.profile_picture}')`,
                                                                    }}
                                                                ></div>
                                                                :
                                                                <img className="profile-image" src="https://s3-us-west-2.amazonaws.com/cherpa01-static/media/placeholders/avatar.jpg" alt={''}></img>
                                                        }
                                                    </div>

                                                    <h2>{courseDetail?.author?.first_name && courseDetail?.author?.last_name ?
                                                        `${capitalizeFirstWord(courseDetail?.author?.first_name)} ${capitalizeFirstWord(courseDetail?.author?.last_name)}`
                                                        :
                                                        `${courseDetail?.author?.username}`
                                                    }</h2>
                                                </div>

                                                <p className="text">
                                                    {courseDetail?.author?.description ? formattedTextWithNewLine(courseDetail?.author?.description) : ''}
                                                </p>
                                            </div>

                                            {/* <div className="card" style={{ marginTop: "50px" }}>
                                            <h1 className="course-details-title">Course Details</h1>

                                            <p className="text" style={{ marginBottom: "100px" }}>
                                                Stock Investing 101 is the first step For beginners in the world of
                                                investing in
                                                stocks. This course offers a unique opportunity for participants to
                                                understand...
                                                The basics of investing in stocks and learning the methods The basic
                                                techniques
                                                for
                                                success in this field.
                                            </p>

                                            <h1 className="card-text-title">How to deal with the interactive course:</h1>

                                            <div className="card-bullet-points-container">
                                                {
                                                    Array(5)
                                                        .fill()
                                                        .map((item, index) => {
                                                            return (
                                                                <div className="card-bullet-points"
                                                                    key={"course-details" + index}>
                                                                    <div className="bullet-points-number">
                                                                        <p className="m-0">{index + 1}</p>
                                                                    </div>
                                                                    <p className="color-gray">
                                                                        Make sure to write the phone number in the phone
                                                                        fields
                                                                        And
                                                                        WhatsApp in the correct way, which is by starting
                                                                        with
                                                                        the
                                                                        number For example, with the country key
                                                                        (XXXXXXXXXX+971).
                                                                    </p>
                                                                </div>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </div> */}
                                        </div>

                                        <div className="left-section">
                                            <div className="card">
                                                <h1 className="learning-objectives-title">About the course</h1>

                                                <p className="text">
                                                    {courseDetail?.description ? formattedTextWithNewLine(courseDetail?.description) : ''}
                                                </p>

                                                {
                                                    uniqueLearningObjectives?.length > 0 &&
                                                    <h1 className="learning-objectives-title" style={{ marginTop: '50px' }}>Learning objectives</h1>
                                                }

                                                {/* <h1 className="card-text-title">How to deal with the interactive course:</h1> */}
                                                <div className="card-bullet-points-container">
                                                    {uniqueLearningObjectives?.length > 0 &&
                                                        uniqueLearningObjectives.map((item, index) => {
                                                            return <div className="card-bullet-points" key={`learning-objective-${index}`}>
                                                                <div className="bullet-points-number learning-objectives-bullet-bg">
                                                                    <p className="m-0">{index + 1}</p> {/* Use the incremented counter */}
                                                                </div>
                                                                <div className="learning-objectives-description">
                                                                    <h6 className="card-text-title">{item.name}</h6>
                                                                    <p className="color-gray">
                                                                        {item.description}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="additional-media">
                                        <Modal open={!!selectedImage} style={{ zIndex: 1000 }} size="mini" onClose={() => {
                                            setSelectedImage('');
                                        }}>
                                            <Modal.Content className="modal-content image-modal">
                                                <div
                                                    className="close-icon"
                                                    onClick={() => {
                                                        setSelectedImage('');
                                                    }}>x</div>
                                                <img src={staticBaseURLAPI7 + selectedImage} style={{ width: '100%', borderRadius: '10px' }} alt="image_selected" />
                                            </Modal.Content>
                                        </Modal>

                                        <Slider {...slickSettings}>
                                            {courseDetail?.additional_media && courseDetail?.additional_media?.length > 0 &&
                                                courseDetail?.additional_media.map((img, idx) => {
                                                    return (
                                                        <div
                                                            key={idx}
                                                            className={idx === ImageIndex ? "slide activeSlide" : "slide"}
                                                            onMouseDown={handleMouseDown}
                                                            onMouseUp={(e) => handleMouseUp(e, img)}
                                                        >
                                                            <img className='slider-image' src={staticBaseURLAPI7 + img} alt={img} />
                                                        </div>
                                                    );
                                                })}
                                        </Slider>
                                    </div>
                                </>
                            }
                        </div>
                        :
                        <p className="error-message">404 - Not found</p>
            }

            {
                props.width > 480 && !isPaymentFormDisplayed &&
                <Footer />
            }

            {
                props.width < 480 && !isPaymentFormDisplayed &&
                <MobileFooter />
            }
        </>
    );
};

export default CourseDetails;
