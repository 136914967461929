import './Main.scss';
import AosDiv from './utils/AosDiv';
import ClientsSlider from './ClientsSlider';
import rightImage1 from '../assets/img/header/1.png';
import rightImage2 from '../assets/img/header/2.png';
import rightImage3 from '../assets/img/header/3.png';
import rightImage5 from '../assets/img/header/5.png';
import {staticBaseURL} from './config';
//import mobileImage from '../assets/img/main-mobile-image.png';
//import MainMobile from '../components/MainMobile';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect } from 'react';

const Main = ({ width, home, lang }) => {
	const ref = useRef(null);

	useEffect(() => {
		home(ref);
	}, [home]);

	const { t, i18n } = useTranslation();
	document.body.dir = i18n.dir();

	return (
		<main ref={ref}>
			<div className='main-flex-container'>
				<AosDiv className='main-left' dataAos='fade-right'>
					<h1>
						{t('conversational')}
						<br />
						{t('mobile')}
					</h1>
					<h3>{t('frictionless')}</h3>

					<a className='main-demo' onClick={
						() => { window.Calendly.initPopupWidget({ url: 'https://calendly.com/eflow-demo' }); }
					}>{t('bookADemo')}</a>

					{width > 1000 && <br />}
					{/*<a href='/#' className='main-watch'>
						{t('watch_video')}
				</a>*/}
				</AosDiv>
				<AosDiv dataAos='fade' offset='1' className='main-container-right'>
					<div className='hero-animation-img main-right'>
						<img className={i18n.language === "en" ? "main-img-01" : "main-img-01-ar"} src={staticBaseURL + "fb-banner.png"} alt='left' />
						<img className={i18n.language === "en" ? "main-img-02" : "main-img-02-ar"} src={staticBaseURL + "slack-banner.png"} alt='left' />
						<img className={i18n.language === "en" ? "main-img-03" : "main-img-03-ar"} src={staticBaseURL + "ms-teams.png"} alt='left' />
						<img className={i18n.language === "en" ? "main-img-04" : "main-img-04-ar"} src={staticBaseURL + "wa-banner.png"} alt='left' />
					</div>
				</AosDiv>
				{/* <div className='mobile-image'>
						<img src={rightImage1} alt='left' />
						<img src={rightImage2} alt='left' />
						<img src={rightImage3} alt='left' />
						<img src={rightImage4} alt='left' />
						<img src={rightImage5} alt='left' />
					</div> */}
			</div>

			<AosDiv dataAos='' className='main-clients'>
				<h3>{t('our_clients')}</h3>
				<ClientsSlider />
			</AosDiv>
		</main>
	);
};

export default Main;
