//SmallScreensNavbar.js
import React, { useState } from 'react';
import { NavComponent } from './Navbar';

const SmallScreensNavbar = ({ headerSelection }) => {
	// declare 'translate' as a state variable
	let [translate, setTranslate] = useState(false);
	return (
		<div>
			<button
				className='hamburger-btn'
				onClick={() => setTranslate(!translate)}
			>
				{!translate ? <span>&#9776;</span> : <span>&times;</span>}
			</button>
			<div
				id='sidebar-list'
				className={`${translate ? 'addTransition' : 'removeTransition'}`}
			>
				<NavComponent
					navClass='nav-small'
					linkClassName='nav-small-link'
					headerSelection={(e) => {
						headerSelection(e);
						setTranslate(!translate)
					}}
					onClick={() => setTranslate(true)} //set translate to true to hide the sidebar list
				/>
			</div>
		</div>
	);
};
export default SmallScreensNavbar;
