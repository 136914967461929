import { useState, useEffect } from 'react';
import Navbar from './Navbar';
import { Link } from 'react-scroll'; // react-scroll is a library for scrolling in React
import SmallScreensNavbar from './SmallScreensNavbar';
import { useWindowWidthAndHeight } from '../utils/useWindowWidthAndHeight';
import logo from '../../assets/img/logo.png';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { i18n } from '../../translations/i18n';
import { staticBaseURL } from '../config';

import './NewHeader.scss';

const NewHeader = (props) => {
	const [language, setLanguage] = useState('en');
	const [width] = useWindowWidthAndHeight();
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const handleChangeLanguage = (e) => {
		e.preventDefault();
		setLanguage(e.target.value);
		if (t) i18n.changeLanguage(e.target.value);
		i18n.changeLanguage(e.target.value)
		document.body.dir = i18n.dir();
	};

	const [offset, setOffset] = useState(0);

	useEffect(() => {
		const onScroll = () => setOffset(window.pageYOffset);
		window.removeEventListener('scroll', onScroll);
		window.addEventListener('scroll', onScroll, { passive: true });
		return () => window.removeEventListener('scroll', onScroll);
	}, []);

	return (
		<header>
			<div className='header-inner'>
				<Link
					to='Home'
					smooth={true}
					className='logo nav-link'
					onClick={() => window.location.href = '/'}
				>
					<div className='logo'>
						<img className='header-logo' src={staticBaseURL + "eflow_logo_white.png"} alt='' />
					</div>
				</Link>
				{width > 1000 ? (
					<Navbar
						offset={offset}
						headerSelection={(e) => props.headerSelection(e)}
						navClass='nav-big'
						linkClassName={'nav-big-link'}
					/>
				) : (
					<SmallScreensNavbar
						headerSelection={(e) => props.headerSelection(e)}
						navClass='nav-small'
						linkClassName='nav-small-link'
					/>
				)}
			</div>
			{/* <div className='language-selector'>
				<button
					className={
						language === 'en' ? 'language-button selected' : 'language-button'
					}
					value='en'
					onClick={handleChangeLanguage}
				>
					EN
				</button>
				&nbsp;
				<button
					className={
						language === 'ar' ? 'language-button selected' : 'language-button'
					}
					value='ar'
					onClick={handleChangeLanguage}
				>
					AR
				</button>
			</div> */}
		</header>
	);
};
export default NewHeader;
