import './Steps.scss';
//import image1 from '../assets/img/step-1.png';
//import image2 from '../assets/img/step-2.png';
//import image3 from '../assets/img/step-3.png';

import image1 from '../assets/img/authoring_flow.png';
import image2 from '../assets/img/phone1.png';
import image2_2 from '../assets/img/phone2.png';
import image3 from '../assets/img/eflow_dashboard.png';
import step01 from '../assets/img/learner-cyntric/step-01.png';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { staticBaseURL } from './config';

const Steps = ({ width, productRef }) => {

	const { t } = useTranslation();
	const ref = useRef(null);

	useEffect(() => {
		productRef(ref);
	}, [productRef]);

	return (
		<section id='tools-container' ref={ref} className='steps-section-container'>
			<div className='section-container'>
				<div className='steps-section-title'>
					<p className='stepSectionTitle COMMON_TITLE_BOLD'>{t('create_educational_experience')}</p>
					<p className='stepSectionSubTitle'>{t('in_3_easy_steps')}</p>
				</div>
				{
					/*width > 500*/ true ?
						<div className='step step01'>
							<div className='step01-content'>
								<div className='content-01 content'>
									<div className='description'>
										<div className='title'>
											<p className='step-title COMMON_TITLE_BOLD'>
												{t('step01_title')}
												<br />
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													💬 Text messages
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													🖼️ Images
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													📹 Videos
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													🎤 Voice notes
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													🧩 Interactive activities
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													💭 Social boards
												</span>
											</p>
										</div>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											💬 Text messages
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											🖼️ Images
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											📹 Videos
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											🎤 Voice notes
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											🧩 Interactive activities
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											💭 Sharing boards
										</p>
									</div>
								</div>
							</div>
							<div dataAos='fade' className='step-image step01-image'>
								{/* <img src={staticBaseURL + "step-01.png"} alt='1' /> */}
								<img src={step01} alt='1' />
							</div>
						</div>
						:
						<div className='step step01'>
							<div dataAos='fade' className='step-image step01-image'>
								<img src={staticBaseURL + "step-01.png"} alt='1' />
							</div>
							<div dataAos='fade' className='step01-content'>
								<div className='content'>
									<div className='description'>
										<div className='title'>
											<p className='step-title COMMON_TITLE_BOLD'>
												{t('step01_title')}
												<br />
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													💬 Text messages
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													🖼️ Images
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													📹 Videos
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													🎤 Voice notes
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													🧩 Interactive activities
												</span>
												<span className='mobile-description'>
													{/* {t('step01_description')} */}
													💭 Social boards
												</span>
											</p>
										</div>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											💬 Text messages
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											🖼️ Images
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											📹 Videos
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											🎤 Voice notes
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											🧮 Interactive activities
										</p>
										<p className='step-text'>
											{/* {t('step01_description')} */}
											💭 Social boards
										</p>
									</div>
								</div>
							</div>
						</div>
				}
				<div className='step inverse step02'>
					<div dataAos='fade' className='step-image step02-image'>
						<img src={staticBaseURL + "step02.png"} alt='2' />
					</div>
					<div dataAos='fade' className='step-image step02-image'>
						<img src={staticBaseURL + "step03.png"} alt='2_2' />
					</div>
					<div dataAos='fade' className='content'>
						<div className='title'>
							<p className='step-title COMMON_TITLE_BOLD'>
								{t('step02_title')}
								<span className='mobile-description-sub'>
									{/* {t('step02_description')} */}
									📲 Delivered directly to the learners on instant messaging apps
								</span>
								<span className='mobile-description-sub'>
									{/* {t('step02_description')} */}
									🗒️ Instant feedback
								</span>
								<span className='mobile-description-sub'>
									{/* {t('step02_description')} */}
									✅ Self-paced learning through a chatbot
								</span>
							</p>
						</div>
					</div>
				</div>
				<div className='step step03'>
					<div dataAos='fade' className='step03-image'>
						<img src={staticBaseURL + "step04.png"} alt='3' />
					</div>
					<div dataAos='fade'>
						<div className='content'>
							<div className='description'>
								<div className='title'>
									<p className='step-title COMMON_TITLE_BOLD'>
										{t('step03_title')}
										<br />
										<span className='mobile-description'>
											{/* {t('step03_description')} */}
											✅ Advanced analytics
										</span>

										<span className='mobile-description'>
											{/* {t('step03_description')} */}
											📈 Performance
										</span>

										<span className='mobile-description'>
											{/* {t('step03_description')} */}
											📊 Interactions
										</span>

										<span className='mobile-description'>
											{/* {t('step03_description')} */}
											🏁 Completion rate
										</span>
									</p>
								</div>
								<p className='step-text'>
									{/* {t('step03_description')} */}
									✅ Advanced analytics
								</p>
								<p className='step-text'>
									{/* {t('step03_description')} */}
									📈 Performance
								</p>
								<p className='step-text'>
									{/* {t('step03_description')} */}
									📊 Interactions
								</p>
								<p className='step-text'>
									{/* {t('step03_description')} */}
									🏁 Completion rate
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Steps;
