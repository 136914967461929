import "./Courses.scss";
import Footer from "./Footer";
import General from "./axios/General";
import { Button } from "react-bootstrap";
import MobileFooter from "./MobileFooter";
import { staticBaseURLAPI7 } from "./config";
import React, { useEffect, useState } from "react";
import { Card, Header, Icon } from "semantic-ui-react";
import { englishToArabicNumbers, truncateText } from "../helper";
import WhatsApp from '../../src/assets/whatsapp.png';
import { useTranslation } from "react-i18next";

const Courses = ({ width }) => {
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredCourses, setFilteredCourses] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        getCourses();
    }, []);

    const getCourses = () => {
        setIsLoading(true);

        new General().getNullableCategoriesCourses(
            null,
            (response) => {
                const filteredCourses = response.body
                    .filter(item => item.image)
                    .slice(0, 10);

                setCourses(filteredCourses);
                setFilteredCourses(filteredCourses);
                setIsLoading(false);
            }, (error) => {
                console.log(error);
                setIsLoading(false);
            }
        );
    };

    const renderCardFooter = (course, language) => {
        return (
            <div className="course-duration-level">
                <div className={`card-type ${language === 'Arabic' ? 'rtl' : ''}`}>
                    {course?.duration !== 0 &&
                        <span>
                            <img className="card-type-icon" src="https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/elements/duration.png" alt="time" />
                            {course && course?.duration ? language === 'Arabic' ? englishToArabicNumbers(course?.duration + '') : course?.duration : 0} {t('minutes', { lng: language === 'Arabic' ? 'ar' : 'en' })}
                        </span>
                    }
                </div>

                <div className={`card-type ${language === 'Arabic' ? 'rtl' : ''}`}>
                    {course?.level !== null &&
                        <span>
                            <img src="https://eflow-static02-landing.s3.us-west-2.amazonaws.com/media/elements/level.png" alt="level" />
                            {t(course?.level, { lng: language === 'Arabic' ? 'ar' : 'en' })}
                        </span>
                    }
                </div>
            </div>
        );
    }

    return (
        <div className="courses-container">
            <div className="course-container-section">
                <div className="header-container">
                    <h1 className="COMMON_TITLE_BOLD header-title">
                        Micro-courses meeting
                        <br />
                        you wherever you are.
                    </h1>

                    <h6 className="sub-header-title">
                        Available on WhatsApp and other instant messaging apps.
                    </h6>

                    {courses.length > 10 &&
                        <input
                            className="search-input"
                            name="search"
                            type="search"
                            placeholder="Search"
                            onChange={(event) => {
                                setFilteredCourses(
                                    courses.filter((item) => item.name.toLowerCase().includes(event.target.value))
                                );
                            }}
                        />
                    }
                </div>
            </div>

            {isLoading ? (
                <div className="details-container-loader">
                    <span className="loader"></span>
                </div>
            ) : (
                filteredCourses && filteredCourses.length > 0 ? (
                    <div className="courses-grid">
                        {filteredCourses.map((course, key) => (
                            <div className="card-container" key={key}>
                                <Card>
                                    <div
                                        className="card-image"
                                        style={{ backgroundImage: `url('${staticBaseURLAPI7 + course.image}')` }}
                                    />

                                    <Header className={`header-2 ${course?.language?.name === 'Arabic' ? 'rtl' : ''}`}>
                                        {course.name}
                                    </Header>

                                    <p className={`content ${course?.language?.name === 'Arabic' ? 'rtl' : ''}`}>
                                        {truncateText(course.description, 60)}
                                    </p>

                                    {renderCardFooter(course, course?.language?.name)}

                                    <Button
                                        className="btn-course-details"
                                        onClick={() => {
                                            window.location.href = `/coursesDetails/${course.id}`;
                                        }}
                                    >
                                        <img className="channel-icon" src={WhatsApp} alt="whatsapp.png" />
                                        {t('Enroll', { lng: course?.language?.name === 'Arabic' ? 'ar' : 'en' })}
                                    </Button>
                                </Card>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="details-container-loader">
                        <h1>No courses found</h1>
                    </div>
                )
            )}

            {width > 480 && <Footer />}

            {width < 480 && <MobileFooter />}
        </div>
    );
};

export default Courses;
