import AxiosService from './AxiosService';

export default class General {
    contactForm(data, onSuccess, onError) {
        AxiosService('post', '/contact', data, onSuccess, onError);
    }

    getNullableCategoriesCourses(data, onSuccess, onError) {
        AxiosService('get', '/courses/getLandingPageCourses?nullable_categories=true', null, onSuccess, onError);
    }

    getCourseDetails(data, onSuccess, onError) {
        AxiosService('get', `/courses/getLandingPageCourse/${data.id}`, null, onSuccess, onError);
    }

    orderCourse(data, onSuccess, onError) {
        AxiosService('post', `/order/course`, data, onSuccess, onError);
    }

    checkPromoCodeValidity(data, onSuccess, onError) {
        AxiosService('post', `/promocode/checkPromoCodeValidity`, data, onSuccess, onError);
    }

    sendOtpMessage(data, onSuccess, onError) {
        AxiosService('post', `/landing/sendOtpMessage`, data, onSuccess, onError);
    }

    submitOtpMessage(data, onSuccess, onError) {
        AxiosService('post', `/landing/submitOtpMessage`, data, onSuccess, onError);
    }
}
