import './Channels.scss';
import channelImg from '../assets/img/channels/channels.png';
import channelImgAr from '../assets/img/channels/channels_ar.png';
import { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {staticBaseURL} from './config';

const Channels = ({ integrationsRef, width }) => {

    const ref = useRef(null);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        integrationsRef(ref);
    }, [integrationsRef]);

    const handleScroll = (position) => {
		const offset = 50;
		const element = document.getElementById(position);
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = element.getBoundingClientRect().top;
		const elementPosition = elementRect - bodyRect;
		const offsetPosition = elementPosition - offset;

		window.scrollTo({
			top: offsetPosition - 70,
			behavior: 'smooth'
		});
    }

    const handleSubmit = () => {
       handleScroll('contact-container');
        /*
        window.open(
            'https://demo.eflow.app/signup',
            '_blank' // <- This is what makes it open in a new window.
        );
        */
    }

    return (
        <section id='integration-container' ref={ref} className="channels-section-container">
            <div className='channels-container'>
                <div className='channels-left-container'>
                    <p className='channels-title COMMON_TITLE_BOLD'>
                        {t('seamlessly')} <br /> {t('available')}<br /> {t('highligh_integrable')}
                    </p>
                    <p className='channels-sub-title'>
                        {t('various_apps')}
                    </p>
                    {width > '769' && <button className='channel-btn' onClick={handleSubmit}>{t('create_now')}</button>}

                </div>


                <div className='channels-right-container'>
                    <img className='channelImg' src={i18n.language === "en" ? staticBaseURL + "channels.png" : staticBaseURL + "channels.png"} alt=""/>
                </div>

                {width <= '769' && <button className='channel-btn' onClick={handleSubmit}>{t('create_now')}</button>}
            </div>
        </section>
    );
}

export default Channels;
