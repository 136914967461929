import types from './types.js';

const initialState = {
    groups: [],
    courses: [],
    categories: [],
    organizations: []
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.FILL_COURSES:
            return { ...state, courses: payload }
        case types.FILL_GROUPS:
            return { ...state, groups: payload }
        case types.FILL_CATEGORIES:
            return { ...state, categories: payload }
        case types.FILL_ORGANIZATIONS:
            return { ...state, organizations: payload }
        default:
            return state;
    }
}
