import './Contacts.scss';
import contactBg from '../assets/img/elements-17.png';
import locationIcon from '../assets/img/location-icon.png';
import phoneIcon from '../assets/img/phone-icon.png';
import emailIcon from '../assets/img/email-icon.png';
import AosDiv from './utils/AosDiv';
import validator from 'validator';
import { useTranslation } from 'react-i18next';
import { useRef, useEffect, useState } from 'react';
import General from './axios/General';
import Button from 'react-bootstrap/Button';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';
import { staticBaseURL } from './config';

const Contact = ({ contactUsRef, width }) => {
	const { t, i18n } = useTranslation();
	const ref = useRef(null);

	useEffect(() => {
		contactUsRef(ref);
	}, [contactUsRef]);

	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [isLoading, setIsLoading] = useState(false);
	const [isSent, setIsSent] = useState(false);
	const [status, setStatus] = useState(0);
	const [successMessage, setSuccessMessage] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [token, setToken] = useState('');

	const [emailError, setEmailError] = useState('');
	const [messageError, setMessageError] = useState('');

	const validateEmail = (email) => {
		if (!validator.isEmail(email)) {
			setEmailError(t('Please enter a valid email.'));
			return false;
		}
		setEmailError('');
		return true;
	};

	const validateMessage = (message) => {
		if (message.length <= 10) {
			setMessageError(t('The message must be at least 10 characters.'));
			return false;
		}
		setMessageError('');
		return true;
	};

	const onChange = (value) => {
		setToken(value);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsSent(false);
		setSuccessMessage('');
		setErrorMessage('');

		// Validate required fields
		if (!name || !email || !message) {
			setFormError(t('Please enter the required fields.'));
			return;
		}

		// Validate reCAPTCHA token
		if (!token) {
			setFormError(t('Please verify that you are not a robot.'));
			return;
		}

		// Validate email and message content
		if (!validateEmail(email) || !validateMessage(message)) {
			return;
		}

		// Proceed with form submission
		setIsLoading(true);
		new General().contactForm(
			{ name, email, message },
			(response) => handleSuccess(),
			(error) => handleError(t('Something went wrong, please try again later.'), error)
		);
	};

	// Helper function to handle form success
	const handleSuccess = () => {
		setIsSent(true);
		setIsLoading(false);
		setStatus(true);
		setSuccessMessage(t('Your message has been sent. We will contact you as soon as possible.'));
		resetFormFields();
	};

	// Helper function to handle form error
	const handleError = (errorMessage, error) => {
		setIsSent(true);
		setIsLoading(false);
		setStatus(false);
		setErrorMessage(errorMessage);
		console.log(error.message);
	};

	// Helper function to set error message and reset status
	const setFormError = (message) => {
		setIsSent(true);
		setStatus(false);
		setErrorMessage(message);
	};

	// Helper function to reset form fields
	const resetFormFields = () => {
		setEmail('');
		setName('');
		setMessage('');
		setToken('');
		setEmailError('');
		setMessageError('');
	};

	return (
		<section id='contact-container' ref={ref} className='contactContainer dflex'>
			{width > 480 && <img className='contactBg' src={staticBaseURL + "contact_us_bg.png"} alt='' />}
			<div className='contactHeader'>
				<h2 className='title COMMON_TITLE_BOLD m-auto'>{t('Contact_Us')}</h2>
				<h3>{t('We_are_here_to_help')}</h3>
			</div>
			<div className='dflex contactUsBody'>
				<div className='contactUsForm'>
					{
						isSent && (
							status ?
								<div className='Alert-green-Message'>{successMessage}</div> :
								<div className='Alert-red-Message'>{errorMessage}</div>
						)
					}
					<form onSubmit={handleSubmit}>
						<div>
							<input
								className='form-control contact-input'
								placeholder={"👋 " + t('name')}
								type='text'
								disabled={isLoading}
								value={name}
								onChange={e => setName(e.target.value)}
								required
							/>
						</div>
						<div>
							<input
								className='form-control contact-input'
								placeholder={"📧 " + t('email')}
								type='text'
								disabled={isLoading}
								value={email}
								onChange={e => setEmail(e.target.value)}
								required
							/>
							{emailError && <p className="contact-error-message">{emailError}</p>}
						</div>
						<div>
							<textarea
								style={{ height: '160px' }}
								className='form-control contact-input'
								placeholder={"💬 " + t('message')}
								disabled={isLoading}
								value={message}
								onChange={e => setMessage(e.target.value)}
								required
							/>
							{messageError && <p className="contact-error-message">{messageError}</p>}
						</div>
						<ReCAPTCHA
							sitekey="6LdihjIgAAAAAGyKYSDFU3sFaR7PB3adBuUAbUH1"
							onChange={onChange}
						/>
						<Button
							className='contactSendButton'
							type='submit'
							disabled={isLoading}
						>
							{isLoading ? t('Sending...') : t('Send')}
						</Button>
					</form>
				</div>
				<div className='generalInformation'>
					{/*<div className={i18n.language === "en" ? "infoItem" : "infoItem-ar"}>
						<img width={50} height={50} src={staticBaseURL + "address.png"} alt='' />
						<div>
							<p className='title'>{t('Address')}</p>
							<p className='description'>{t('Address_description')}</p>
						</div>
					</div>*/}
					<div className={i18n.language === "en" ? "infoItem" : "infoItem-ar"}>
						<img width={50} height={50} src={staticBaseURL + "phone.png"} alt='' />
						<div>
							<p className='title'>{t('phone')}</p>
							<p className='description'>+1 (480) 864-8381</p>
						</div>
					</div>
					<div className={i18n.language === "en" ? "infoItem" : "infoItem-ar"}>
						<img width={50} height={50} src={staticBaseURL + "email.png"} alt='' />
						<div>
							<p className='title'>{t('email')}</p>
							<p className='description'>hello@eflow.app</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Contact;
