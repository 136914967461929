import types from './types.js';

const initialState = {
    token: '',
    info: {},
    source: '',
}

export default (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case types.SUCCESSFUL_LOGIN:
            localStorage.setItem("token", payload.token);
            localStorage.setItem("source", payload.source);
            localStorage.setItem('lastTokenUpdate', Date.now());

            return { ...state, token: payload.token, info: payload.info };
        case types.PERFORM_LOGOUT:
            localStorage.removeItem("token");
            localStorage.removeItem("source");
            localStorage.removeItem("persist:root");
            localStorage.removeItem("lastTokenUpdate");

            return {
                token: '',
                info: {}
            }
        default:
            return state;
    }
}
