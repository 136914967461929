import client1 from '../assets/img/clients/export-08.png';
import client2 from '../assets/img/clients/export-09.png';
import client3 from '../assets/img/clients/export-10.png';
import client4 from '../assets/img/clients/export-11.png';
// import client5 from '../assets/img/clients/export-12.png';
import client6 from '../assets/img/clients/export-13.png';
import client7 from '../assets/img/clients/export-14.png';
import client8 from '../assets/img/clients/export-16.png';
import client9 from '../assets/img/clients/aflatoun_logo.png';
import Slider from 'react-slick/lib/slider';
import {staticBaseURL} from './config';
import { useState } from 'react';
const images = ["ilo_logo.png","client01.png","wiley_logo.png","client02.png", "client03.png", "client04.png", "client05.png", "client06.png", "client07.png", "client08.png"];
const ClientsSlider = () => {
	const [ImageIndex, setImageIndex] = useState(0);

	const slickSettings = {
		dots: false,
		infinite: true,
		slidesToShow: 5,
		slidesToScroll: 1,
		focusOnSelect: false,
		autoplay: true,
		speed: 250,
		prevArrow: null,
		nextArrow: null,
		arrows: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 1,
					infinite: true,
					dots: false
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
					initialSlide: 1,
					dots: false
				}
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 2,
					dots: false,
					autoplay: true,
					speed: 500,
				}
			}
		],
		beforeChange: (current, next) => setImageIndex(next)
	};

	return (
		<Slider {...slickSettings} >
			{images.map((img, idx) => (
				<div key={idx} className="slide">
					<img className='slider-image' key={idx} src={staticBaseURL + img} alt={img} />
				</div>
			))}
		</Slider>
	);
};
export default ClientsSlider;
