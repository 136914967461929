import './PaymentForm.scss';
import React, { useEffect, useState } from 'react';
import General from "./axios/General";
import { useTranslation } from "react-i18next";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import barcode from '../assets/img/visa-cards/promocode.png';
import { staticBaseURLAPI7 } from './config';
import { Button } from 'react-bootstrap';

import credit_card from '../assets/img/credit_card.svg';
import card_amex from '../assets/img/visa-cards/card-amex.svg';
import card_discover from '../assets/img/visa-cards/card-discover.svg';
import card_jcb from '../assets/img/visa-cards/card-jcb.svg';
import card_mastercard from '../assets/img/visa-cards/card-mastercard.svg';
import card_visa from '../assets/img/visa-cards/card-visa.svg';
import lock from '../assets/img/visa-cards/lock.svg';
import { useToasts } from 'react-toast-notifications';

const PaymentForm = ({ onSucceededPayment, recaptcha, course, phoneNumber }) => {
    const stripe = useStripe(),
        elements = useElements(),
        { addToast } = useToasts(),
        { t } = useTranslation(),
        [selectedChannel, setSelectedChannel] = useState('whatsapp'),
        [isProcessingPayment, setIsProcessingPayment] = useState(false),
        [isPaymentProcessLoading, setIsPaymentProcessLoading] = useState(false),
        [selectedTab, setSelectedTab] = useState("card"),

        // payment checked
        [paymentValidated, setPaymentValidated] = useState(false),

        // promo code
        [isCheckingPromo, setIsCheckingPromo] = useState(false),
        [promoCode, setPromoCode] = useState(""),
        [discountedPrice, setDiscountedPrice] = useState(0),
        [promoValided, setPromoValided] = useState(false);

    const [isCardValid, setIsCardValid] = useState(false);

    useEffect(() => {
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const handleChange = event => {
            setIsCardValid(event.complete && event.error === undefined);
        };

        cardElement.on('change', handleChange);

        // Cleanup
        return () => {
            cardElement.off('change', handleChange);
        };
    }, [stripe, elements]);


    const handleSubmit = async () => {
        try {

            // check if promo code that the user has is 100%, if yes skip the cheking for credit card
            if (paymentValidated && discountedPrice === 100) {
                // do nothing and continue to payment
            } else if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                return;
            }

            setIsPaymentProcessLoading(true);
            let result = [];
            if (discountedPrice !== 100) {
                result = await stripe.createPaymentMethod({
                    type: 'card',
                    card: elements.getElement(CardElement),
                });
            }

            if (result?.error && discountedPrice !== 100) {
                addToast("Card details is wrong", {
                    autoDismiss: true,
                    appearance: "error"
                });
                setIsPaymentProcessLoading(false);
                console.error(result.error);
            } else {
                setIsProcessingPayment(true);
                new General().orderCourse({
                    course_id: course.id,
                    recaptcha: recaptcha,
                    phone_number: phoneNumber,
                    payment_method_id: result?.paymentMethod?.id,
                    promo_code: promoCode // send promocode if it is present else send ''
                }, () => {
                    setIsPaymentProcessLoading(false);
                    onSucceededPayment();
                    setIsProcessingPayment(false);
                }, (error) => {
                    addToast(error.response.data.message, {
                        autoDismiss: true,
                        appearance: "error"
                    });
                    setIsPaymentProcessLoading(false);
                    setIsProcessingPayment(false);
                    console.log(error.response.data.message);
                }
                );
            }
        } catch (e) {
            setIsPaymentProcessLoading(true);

            console.log("error", e);
        }
    };

    const checkPromoCode = () => {
        setIsCheckingPromo(true);

        // call api to check promo
        new General().checkPromoCodeValidity({
            code: promoCode
        }, (response) => {
            setDiscountedPrice(response.discount);
            setIsCheckingPromo(false);
            setPromoValided(true);
        }, (error) => {
            addToast(t("wrong_promo_code"), {
                autoDismiss: true,
                appearance: "error"
            });
            setIsCheckingPromo(false);
            setPromoValided(false);
        }
        );
    }

    return (
        <div className="payment-form-container">
            <div className='right-section'>
                <div className='right-content'>
                    <h1 className='section-header'>Checkout</h1>

                    <h1 className='section-header-title'>Billing Address</h1>

                    <h1 className='section-header-sub-title' style={{ color: '#8fdb79' }}>Select A Channel</h1>

                    <select
                        className='select-parent font-16'
                        value={selectedChannel} onChange={(event) => {
                            setSelectedChannel(event.target.value);
                        }}>
                        <option value="">Select an option</option>
                        <option value="whatsapp">WhatsApp</option>
                    </select>

                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <h1 className='section-header-title'>Payment Information</h1>
                        <p className='secure-connection-text'>Secure Connection<img src={lock} alt='lock' /></p>
                    </div>

                    <section className='payment'>
                        {/* Card 1 */}
                        <div className='payment-card mb-0'
                            onClick={() => {
                                setSelectedTab('promo');
                            }}
                        >
                            <div className='payment-card--header'>
                                <div className='d-flex'>
                                    {/* <input className='radio' type='radio' checked={selectedTab === 'promo'} /> */}
                                    <img className='card-default' src={barcode} alt='card_default' />
                                    <p className='p-0 m-0 font-16'>Promocode</p>
                                </div>
                            </div>

                            {
                                <div className='card-body font-16'>
                                    <input
                                        type='text'
                                        className='input'
                                        value={promoCode}
                                        placeholder='12345678'
                                        disabled={isCheckingPromo || promoValided}
                                        onChange={(event) => {
                                            setPromoCode(event.target.value);
                                        }}
                                    />

                                    <Button
                                        style={{ fontSize: '16px' }}
                                        onClick={checkPromoCode}
                                        className="validate-button font-16"
                                        disabled={promoCode.length <= 4 || isCheckingPromo || promoValided}
                                    >
                                        {isCheckingPromo ? "Loading..."
                                            :
                                            promoValided
                                                ?
                                                "Validated"
                                                :
                                                'Validate Promo'}
                                    </Button>
                                </div>
                            }
                        </div>

                        {/* Card 2 */}
                        {
                            discountedPrice !== 100 &&
                            <div className='payment-card'>
                                <div className='payment-card--header'
                                    onClick={() => {
                                        setSelectedTab('card');
                                    }}
                                >
                                    <div className='d-flex' style={{ marginRight: '1rem' }}>
                                        {/* <input className='radio' type='radio' checked={selectedTab === 'card'} /> */}
                                        <img className='card-default' src={credit_card} alt='card_default' />
                                        <p className='p-0 m-0 font-16'>Credit/Debit Card</p>
                                    </div>

                                    <div className='d-flex'>
                                        <img className='card-default-visa' src={card_amex} alt='card_default' />
                                        <img className='card-default-visa' src={card_discover} alt='card_default' />
                                        <img className='card-default-visa' src={card_mastercard} alt='card_default' />
                                        <img className='card-default-visa' src={card_visa} alt='card_default' />
                                        <img className='card-default-visa' src={card_jcb} alt='card_default' />
                                    </div>
                                </div>

                                <div className='card-body'>
                                    <div className={'payment-card-element'}>
                                        <label>
                                            <p className='font-16'>Card Details:</p>

                                            <CardElement
                                                options={{
                                                    style: {
                                                        invalid: { color: '#9e2146' },
                                                        base: {
                                                            fontSize: '16px',
                                                            color: '#424770',
                                                            '::placeholder': { color: '#aab7c4' }
                                                        }
                                                    }
                                                }}
                                            />

                                        </label>
                                    </div>
                                </div>
                            </div>
                        }
                    </section>

                    <div className='course-details'>
                        <p className='section-header-title'>Order Details</p>
                        <div className='course-details--course-div'>
                            <div className='d-flex' style={{ alignItems: 'center' }}>
                                <img src={staticBaseURLAPI7 + course.image} alt='course_image' style={{ width: '50px', maxHeight: '50px' }} />
                                {course && course.name && <div style={{ display: 'flex', alignItems: 'center', padding: '0 1rem' }}>
                                    <p style={{ margin: '0', flex: '1' }} className='font-16'>
                                        {course.name}
                                    </p>
                                </div>}
                            </div>

                            {course && course.price && <p style={{ minWidth: '50px', textAlign: 'center', margin: '0' }} className='font-16'>{course.price + ' $'}</p>}
                        </div>
                    </div>
                </div>

            </div>

            <div className='left-section'>
                <div className='left-content'>
                    <h1 className="section-header"></h1>

                    <h1 className='section-header-title'>Summary</h1>

                    <div className='pricing-body'>
                        <p>Original Price:</p>
                        {course && course.price && <p className={promoValided ? "strike-through" : ""}>{course.price} $</p>}
                    </div>

                    <div className='pricing-body'>
                        <p>Promo:</p>
                        {promoCode && promoValided ? <p>{discountedPrice}%</p> : <p>0</p>}
                    </div>

                    <div className='seperator'></div>

                    <div className='pricing-body'>
                        <p>Total:</p>
                        {course && course.price && <p>{
                            promoValided ?
                                // original price - (original price - percentage discount)
                                (parseFloat(course.price) - (parseFloat(course.price) * parseFloat(discountedPrice / 100)))
                                :
                                course.price
                        } $</p>}
                    </div>

                    <p style={{ fontSize: '14px', marginTop: '1rem', color: '#6a6f73' }}>By completing your purchase you agree to these Terms of Service.</p>

                    <Button
                        style={{ fontSize: '16px' }}
                        onClick={handleSubmit}
                        className="pay-now-button"
                        disabled={!isCardValid && !promoValided || isPaymentProcessLoading}
                    >
                        {
                            isPaymentProcessLoading
                                ?
                                "Processing your payment,..."
                                :
                                "Pay Now"
                        }
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default PaymentForm;
